import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import {
  BulkAllEmailAccountWarmupSettingsPayload,
  BulkEmailAccountWarmupSettingsPayload,
  EmailAccountId,
  EmailAccountTagsAssignPayload,
  EmailAccountTagsBulkAssignPayload,
  EmailAccountTagsBulkUnassignPayload,
  EmailAccountTagsUnassignPayload,
  EmailAccountWarmupFilters,
  EmailAccountWarmupSettingsPayload,
  EmailWarmupDeliverabilityPayload,
} from '../types';

export const getEmailAccountsInWarmup = async (
  options: EmailAccountWarmupFilters,
) =>
  api.post(`email-account/email-warmup`, {
    ...options,
  });

export const getEmailAccountWarmupDeliverabilityReport = async ({
  id,
  timeFrame,
}: EmailWarmupDeliverabilityPayload) =>
  api.get(`email-account/email-warmup/deliverability/${id}`, {
    params: {
      timeFrame,
    },
  });

export const getEmailAccountWarmupList = async () =>
  api.get('email-account/email-warmup/list');

export const getEmailAccountWarmupSentReceivedReport = async ({
  id,
}: EmailAccountId) => api.get(`email-account/email-warmup/sent-received/${id}`);

export const getEmailAccountWarmupSettings = async ({ id }: EmailAccountId) =>
  api.get(`email-account/email-warmup/settings/${id}`);

export const saveBulkEmailAccountWarmupSettings = async ({
  ...rest
}: BulkEmailAccountWarmupSettingsPayload) =>
  api.patch('email-account/bulk-email-warmup/settings', rest);

export const saveEmailAccountWarmupSettings = async ({
  id,
  ...rest
}: EmailAccountWarmupSettingsPayload) =>
  api.patch(`email-account/email-warmup/settings/${id}`, rest);

export const turnOnOffEmailAccountWarmup = async ({ id }: EmailAccountId) =>
  api.patch(`email-account/email-warmup/${id}`);

export const importEmailAccountCsv = (file: File) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/email-account/bulk-connect', data);
};

export const syncSaleshandyEmailAccountWithApiKey = (apiKey: string) => {
  return api.post('/sh-api-key/verify', {
    apiKey,
  });
};

export const getSaleshandyApiKey = () => {
  return api.get('/sh-api-key');
};

export const getTagsInEmailWarmup = async () =>
  api.get<ResponseSuccess>(`/email-account/tags/list`);

export const getEmailAccountTags = async () =>
  api.get('/email-account/tags/list');

export const emailAccountTagsAssign = async (
  payload: EmailAccountTagsAssignPayload,
) => api.post('/email-account/tag/assign', payload);

export const emailAccountTagsBulkAssign = async (
  payload: EmailAccountTagsBulkAssignPayload,
) => api.post('/email-account/tag/bulk-assign', payload);

export const emailAccountTagsUnassign = async (
  payload: EmailAccountTagsUnassignPayload,
) => api.post('/email-account/tag/un-assign', payload);

export const emailAccountTagsBulkUnassign = async (
  payload: EmailAccountTagsBulkUnassignPayload,
) => api.post('/email-account/tag/bulk-un-assign', payload);

export const updateAllEmailAccountWarmupSettings = async (
  payload: BulkAllEmailAccountWarmupSettingsPayload,
) => api.patch('/email-account/bulk-email-warmup/bulk-settings', payload);
