import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import EmailWarmup from './email-warmup';
import { RootState } from '../../store/root-reducer';
import {
  getTagsEmailWarmupRequest,
  importEmailAccountCsvRequest,
  syncSaleshandyEmailAccountWithApiKeyRequest,
  turnOnOffEmailAccountWarmupRequest,
} from './extra-actions';
import { EmailAccountId } from './types';
import {
  connectEmailAccountRequest,
  getEmailAccountsRequest,
  getSmtpImapAccountDetailsRequest,
} from '../settings/components/email-account/extra-actions';
import { EmailAccountMethod } from '../settings/enums/email-account';
import { resetTurnOnOffEmailAccountWarmupState } from './email-warmup-slice';
import {
  getPostLoadMetaRequest,
  getUserSettingsRequest,
  updateUserSettingsRequest,
} from '../home/extra-actions';
import { UpdateUserSettingsRequestPayload } from '../sequence/types';
import {
  resetSmtpIMapConnectResponse,
  resetSmtpImapTestConnection,
  resetUpdateSmtpImapAccountDetails,
} from '../settings/components/email-account/email-account-slice';

const mapStateToProps = (state: RootState) => ({
  authUrl: state.emailAccount.authUrl,
  subscriptionPlan: state.home.subscription?.planCode,
  meta: state.home.meta,
  planCode: state.home?.subscription?.planCode,

  turnOnOffEmailAccountWarmupRequestStatus:
    state.emailWarmup.turnOnOffEmailAccountWarmupRequest.status,
  turnOnOffEmailAccountWarmupRequestError:
    state.emailWarmup.turnOnOffEmailAccountWarmupRequest.error,

  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  connectEmailAccountRequestError:
    state.emailAccount.connectEmailAccountRequest.error,

  updateUserSettingsRequestStatus: state.home.updateUserSettingsRequest.status,

  agencyConfig: state.home.agencyConfig,

  sendConnectSmtpImapResponse:
    state.emailAccount.connectSmtpImapAccountResponse,
  getConnectSmtpImapRequestMessage:
    state.emailAccount.connectSmtpImapAccountRequest.message,

  sendUpdateSmtpImapAccountDetailsResponse:
    state.emailAccount.sendUpdateSmtpImapAccountDetailsResponse,
  sendUpdateEmailAccountRequestMessage:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.message,

  getTestSMTPConnectionRequestStatus:
    state.emailAccount.testSMTPConnectionRequest.status,
  getTestIMAPConnectionRequestStatus:
    state.emailAccount.testIMAPConnectionRequest.status,

  getConnectSmtpImapRequestStatus:
    state.emailAccount.connectSmtpImapAccountRequest.status,

  sendUpdateSmtpImapAccountRequestStatus:
    state.emailAccount.updateSmtpImapAccountDetailsRequest.status,

  sendGetSmtpImapAccountDetailsRequestStatus:
    state.emailAccount.getSmtpImapAccountDetailsRequest.status,

  importEmailAccountCsvRequestStatus:
    state.emailWarmup.importEmailAccountCsvRequest.status,
  isSaleshandyAccountSynced: state.home.syncConsent,

  syncSaleshandyEmailAccountWithApiKeyRequestStatus:
    state.emailWarmup.syncSaleshandyEmailAccountWithApiKeyRequest.status,
});

const mapDispatchToProps = {
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  sendTurnOnOffEmailAccountWarmupRequest: (payload: EmailAccountId) =>
    turnOnOffEmailAccountWarmupRequest(payload),
  resetTurnOnOffEmailAccountWarmupState: () =>
    resetTurnOnOffEmailAccountWarmupState(),
  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateEnableEmailWarmupConsent: (payload: UpdateUserSettingsRequestPayload) =>
    updateUserSettingsRequest(payload),
  sendGetEmailAccountsRequest: () => getEmailAccountsRequest(),
  resetSmtpImapTestConnection: () => resetSmtpImapTestConnection(),
  resetSmtpIMapConnectResponse: () => resetSmtpIMapConnectResponse(),
  resetUpdateSmtpImapAccountDetails: () => resetUpdateSmtpImapAccountDetails(),
  sendGetSmtpImapAccountDetailsRequest: (emailAccountId: number) =>
    getSmtpImapAccountDetailsRequest(emailAccountId),
  sendGetPostLoadMetaRequest: () => getPostLoadMetaRequest(),

  importEmailAccountViaCsv: (file: File) =>
    importEmailAccountCsvRequest({ file }),

  sendSyncSaleshandyEmailAccountWithApiKey: (apiKey: string) =>
    syncSaleshandyEmailAccountWithApiKeyRequest({ apiKey }),
  sendGetTagsEmailWarmupRequest: () => getTagsEmailWarmupRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(EmailWarmup);
