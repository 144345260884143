/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
export const Images = {
  Promo: require('../assets/images/promo-login.svg').default,
  SHIcon: require('../assets/images/sh-logo-icon.svg').default,
  SHIcon70: require('../assets/images/sh-logo-icon-70x70.svg').default,
  SHLogoSmall: require('../assets/images/sh-logo.svg').default,
  SHLogo: require('../assets/images/saleshandy-logo.svg').default,
  SHLogoIcon: require('../assets/images/saleshandy-logo-icon.svg').default,
  SHLogoText: require('../assets/images/saleshandy-logo-text.svg').default,
  GoogleLogin: require('../assets/images/google-login.svg').default,
  FileCheck: require('../assets/images/file-check.svg').default,
  GoogleIcon: require('../assets/images/google-icon.svg').default,
  GmailIcon: require('../assets/images/gmail-icon.svg').default,
  MicrosoftLogin: require('../assets/images/microsoft-login.svg').default,
  MicrosoftIcon: require('../assets/images/microsoft-logo.svg').default,
  MicrosoftIconSmall: require('../assets/images/microsoft-logo-small.svg')
    .default,
  BrokenLink: require('../assets/images/broken-link.svg').default,
  EmptyData1: require('../assets/images/empty-data-1.svg').default,
  EmptyData2: require('../assets/images/empty-data-2.svg').default,
  EmptyData3: require('../assets/images/empty-data-3.svg').default,
  EmptyData4: require('../assets/images/empty-data-4.svg').default,
  Squiggles: require('../assets/images/squiggles.svg').default,
  StepTypeEmail: require('../assets/images/sequence/step-type-email.svg')
    .default,
  PersonMonocular: require('../assets/images/person-monocular.svg').default,
  NeptikLogo: require('../assets/images/customers/neptik-logo.svg').default,
  MaropostLogo: require('../assets/images/customers/maropost-logo.svg').default,
  KelltonLogo: require('../assets/images/customers/kellton-logo.svg').default,
  DigitalRafterLogo: require('../assets/images/customers/digital-rafter-logo.svg')
    .default,
  YelpLogo: require('../assets/images/customers/yelp-logo.svg').default,
  DoctorLogo: require('../assets/images/vector-doctor.svg').default,
  FilterLogo: require('../assets/images/vector-filter.svg').default,
  MailActive: require('../assets/images/sh-call-active.svg').default,
  MailDisabled: require('../assets/images/mail-disabled.svg').default,
  MailEnabled: require('../assets/images/mail-enable.svg').default,
  EyeActive: require('../assets/images/sh-eye-active.svg').default,
  EyeDisabled: require('../assets/images/sh-eye-disabled.svg').default,
  ReplyActive: require('../assets/images/sh-reply-active.svg').default,
  ReplyDisabled: require('../assets/images/sh-reply-disabled.svg').default,
  LinkActive: require('../assets/images/sh-link-active.svg').default,
  LinkActive2: require('../assets/images/link-enable-2.svg').default,
  LinkDisabled: require('../assets/images/sh-link-disabled.svg').default,
  LinkDisabled2: require('../assets/images/link-disable-2.svg').default,
  ProspectActivityEmailSent: require('../assets/images/email-sent.svg').default,
  ProspectActivityEmailSentBlue: require('../assets/images/email-sent-blue.svg')
    .default,
  EngagementActivityAllTab: require('../assets/images/tab-all.svg').default,
  EngagementActivityAllTabBlue: require('../assets/images/tab-all-blue.svg')
    .default,
  EngagementActivityOpenedTab: require('../assets/images/tab-opened.svg')
    .default,
  EngagementActivityOpenedTabBlue: require('../assets/images/tab-opened-blue.svg')
    .default,
  EngagementActivityClickedTab: require('../assets/images/tab-clicked.svg')
    .default,
  EngagementActivityClickedTabBlue: require('../assets/images/tab-clicked-blue.svg')
    .default,
  EngagementActivityRepliedTab: require('../assets/images/tab-replied.svg')
    .default,
  EngagementActivityRepliedTabBlue: require('../assets/images/tab-replied-blue.svg')
    .default,
  ProspectActivityEmailSentColored: require('../assets/images/email-sent-colored.svg')
    .default,
  ProspectActivityLinkClicked: require('../assets/images/link-clicked.svg')
    .default,
  ProspectActivityLinkClickedColored: require('../assets/images/link-clicked-colored.svg')
    .default,
  ProspectActivityEmailBounced: require('../assets/images/mail-bounced.svg')
    .default,
  ProspectActivityEmailBouncedColored: require('../assets/images/mail-bounced-colored.svg')
    .default,
  ProspectActivityEmailFailed: require('../assets/images/mail-failed.svg')
    .default,
  ProspectActivityEmailFailedColored: require('../assets/images/mail-failed-colored.svg')
    .default,
  ProspectActivityEmailOpened: require('../assets/images/mail-opened.svg')
    .default,
  ProspectActivityEmailOpenedColored: require('../assets/images/mail-opened-colored.svg')
    .default,
  ProspectActivityEmailReceived: require('../assets/images/reply-received.svg')
    .default,
  ProspectActivityEmailReceivedColored: require('../assets/images/reply-received-colored.svg')
    .default,
  ProspectActivityEmailUnsubscribed: require('../assets/images/email-unsubscribed.svg')
    .default,
  ProspectActivityEmailUnsubscribedColored: require('../assets/images/unsubscribed-colored.svg')
    .default,
  Shield: require('../assets/images/sh-shield.svg').default,
  SentEmail: require('../assets/images/send.svg').default,
  SentEmailLarge: require('../assets/images/send-email-l.svg').default,
  SentEmailBlue: require('../assets/images/sent-email-blue.svg').default,
  SentEmailBlueXL: require('../assets/images/email-sent-blue-xl.svg').default,
  SeenEmail: require('../assets/images/activeseen.svg').default,
  SeenEmailCyan: require('../assets/images/seen-email-cyan.svg').default,
  WarningTriangle: require('../assets/images/alert-triangle-warning.svg')
    .default,
  AlertTriangle: require('../assets/images/alert-triangle.svg').default,
  AlertTriangleRed: require('../assets/images/alert-triangle-red.svg').default,
  AlertTriangleLarge: require('../assets/images/alert-triangle-xl.svg').default,
  Clock: require('../assets/images/clock.svg').default,
  Tag: require('../assets/images/tag.svg').default,
  GreyTag: require('../assets/images/tag-grey.svg').default,
  TagBlue: require('../assets/images/tag-blue.svg').default,
  TagBlueXL: require('../assets/images/tag-blue-xl.svg').default,
  Forward: require('../assets/images/forward.svg').default,
  Backward: require('../assets/images/backward.svg').default,
  Close: require('../assets/images/close.svg').default,
  Wallet: require('../assets/images/wallet.svg').default,
  Ban: require('../assets/images/ban.svg').default,
  BanBlueXL: require('../assets/images/ban-blue-xl.svg').default,
  Trash: require('../assets/images/trash.svg').default,
  TrashLight: require('../assets/images/trash-light.svg').default,
  TrashBlueXL: require('../assets/images/trash-blue-xl.svg').default,
  TrashDanger: require('../assets/images/trash-danger.svg').default,
  GreySend: require('../assets/images/send-grey.svg').default,
  SH_Shield: require('../assets/images/shield-check.svg').default,
  SH_ShieldBlueXL: require('../assets/images/shield-blue-xl.svg').default,
  Bell: require('../assets/images/bell.svg').default,
  BellEnabled: require('../assets/images/bell-enabled.svg').default,
  BellDisabled: require('../assets/images/bell-disabled.svg').default,
  Headset: require('../assets/images/headset.svg').default,
  Gift: require('../assets/images/gift.svg').default,
  Help: require('../assets/images/help.svg').default,
  Apps: require('../assets/images/apps.svg').default,
  Logout: require('../assets/images/logout.svg').default,
  Cog: require('../assets/images/bx-cog.svg').default,
  Pencil: require('../assets/images/pencil.svg').default,
  Copy: require('../assets/images/copy.svg').default,
  Upload: require('../assets/images/upload.svg').default,
  UploadCloud: require('../assets/images/upload-cloud.svg').default,
  UploadCloudBlue: require('../assets/images/cloud-upload-blue.svg').default,
  ProspectBlacklistedColored: require('../assets/images/blacklist.svg').default,
  ProspectRemovedFromBlacklistColored: require('../assets/images/removed-from-blacklist.svg')
    .default,
  Export: require('../assets/images/export-icon.svg').default,
  ExportGrey: require('../assets/images/export-grey.svg').default,
  BlacklistDomain: require('../assets/images/clipboard-x.svg').default,
  FileBlank: require('../assets/images/file-blank.svg').default,
  ArrowsSort: require('../assets/images/arrows-sort.svg').default,
  InfoCircle: require('../assets/images/info-circle.svg').default,
  ArrowDown: require('../assets/images/arrow-down.svg').default,
  ArrowForward: require('../assets/images/arrow-forward.svg').default,
  SmileIcon: require('../assets/images/smile-icon.svg').default,
  TemplateIcon: require('../assets/images/template-icon.svg').default,
  InfoCircleYellow: require('../assets/images/info-circle-yellow.svg').default,
  ChecksGreen: require('../assets/images/checks-green.svg').default,
  BanForUnsubscribe: require('../assets/images/ban-for-unsubscribe.svg')
    .default,
  NotificationPause: require('../assets/images/notification-pause.svg').default,
  NotificationAlert: require('../assets/images/notification-alert.svg').default,
  NoNotifications: require('../assets/images/no-notification.svg').default,
  ActiveNotificationBell: require('../assets/images/blue-bell.svg').default,
  LogoutActive: require('../assets/images/blue-logout.svg').default,
  CogActive: require('../assets/images/blue-cog.svg').default,
  HelpActive: require('../assets/images/blue-help.svg').default,
  ChevronLeftBlue: require('../assets/images/chevron-left-blue.svg').default,
  ChevronRightBlue: require('../assets/images/chevron-right-blue.svg').default,
  Peoples: require('../assets/images/peoples.svg').default,
  ProspectAdd: require('../assets/images/prospect-add.svg').default,
  Replied: require('../assets/images/replied.svg').default,
  MailBounced: require('../assets/images/mail-bounced-gray.svg').default,
  AlertCircle: require('../assets/images/alert-circle.svg').default,
  Opened: require('../assets/images/opened.svg').default,
  Clicked: require('../assets/images/clicked.svg').default,
  Unsubscribed: require('../assets/images/unsubscribed.svg').default,
  Calendar: require('../assets/images/calendar.svg').default,
  Mail: require('../assets/images/mail.svg').default,
  Loader: require('../assets/images/loader.svg').default,
  User: require('../assets/images/user.svg').default,
  WalletBlue: require('../assets/images/wallet-blue.svg').default,
  EmptyShield: require('../assets/images/blank-shield.svg').default,
  AlertCircleRed: require('../assets/images/alert-circle-red.svg').default,
  UserCheck: require('../assets/images/user-check.svg').default,
  UserExcremental: require('../assets/images/user-exclamation.svg').default,
  RefreshBlue: require('../assets/images/refresh-blue.svg').default,
  AlertCircleBlue: require('../assets/images/info-circle-blue.svg').default,
  Lock: require('../assets/images/lock.svg').default,
  ChevronLeft: require('../assets/images/chevron-left.svg').default,
  YoutubeLogo: require('../assets/images/youtube.svg').default,
  InfoCircleRed: require('../assets/images/info-circle-red.svg').default,
  ChevronDown: require('../assets/images/chevron-down.svg').default,
  CheckGreen: require('../assets/images/check.svg').default,
  CheckSingleGreen: require('../assets/images/check-single-green.svg').default,
  AlertCircleOrange: require('../assets/images/alert-circle-orange.svg')
    .default,
  Cross: require('../assets/images/cross.svg').default,
  CrossRed: require('../assets/images/cross-red.svg').default,
  CrossGray: require('../assets/images/cross-gray.svg').default,
  Refresh: require('../assets/images/refresh.svg').default,
  RefreshGrey: require('../assets/images/refresh-grey.svg').default,
  RefreshGreen: require('../assets/images/refresh-green.svg').default,
  SmartIdeas: require('../assets/images/smart-ideas.svg').default,
  Server: require('../assets/images/server.svg').default,
  Gmail: require('../assets/images/gmail.svg').default,
  Microsoft: require('../assets/images/microsoft.svg').default,
  Youtube: require('../assets/images/youtube.svg').default,
  ExternalLinkIcon: require('../assets/images/external-link.svg').default,
  ExternalLinkWhite: require('../assets/images/external-link-white.svg')
    .default,
  BanMedium: require('../assets/images/ban-m.svg').default,
  ServerBlue: require('../assets/images/server-blue.svg').default,
  MailSend: require('../assets/images/mail-send.svg').default,
  UserList: require('../assets/images/user-list.svg').default,
  TrashSmall: require('../assets/images/trash-small.svg').default,
  OpenedDisabled: require('../assets/images/opened-disabled.svg').default,
  OpenedEnabled: require('../assets/images/opened-enabled.svg').default,
  OpenedBlue: require('../assets/images/opened-blue.svg').default,
  ClickedDisabled: require('../assets/images/clicked-disabled.svg').default,
  ClickedEnabled: require('../assets/images/clicked-enabled.svg').default,
  ClickedBlue: require('../assets/images/clicked-blue.svg').default,
  OpenedWithBg: require('../assets/images/opened-bg.svg').default,
  ClickedWithBg: require('../assets/images/clicked-bg.svg').default,
  LinkChain: require('../assets/images/link-chain.svg').default,
  Mailbox: require('../assets/images/mailbox.svg').default,
  NoResult: require('../assets/images/no-result.svg').default,
  ChromeLight: require('../assets/images/chrome-light.svg').default,
  ChromeDark: require('../assets/images/chrome-dark.svg').default,
  ChromeGrey: require('../assets/images/chrome-gray.svg').default,
  ChromeBlue: require('../assets/images/chrome-blue.svg').default,
  PlayCircle: require('../assets/images/play-circle.svg').default,
  GooglePlus: require('../assets/images/google-plus.svg').default,
  MicrosoftSM: require('../assets/images/microsoft-sm.svg').default,
  FavIcon: require('../assets/images/favicon.ico').default,
  Setting: require('../assets/images/setting.svg').default,
  TrendingUp: require('../assets/images/trending-up.svg').default,
  TrendingDown: require('../assets/images/trending-down.svg').default,
  PieChart: require('../assets/images/chart-pie.svg').default,
  Email: require('../assets/images/email.svg').default,
  ChevronRight: require('../assets/images/chevron-right.svg').default,
  EmptyPie: require('../assets/images/empty-pie.svg').default,
  Broken: require('../assets/images/broken.svg').default,
  Google30x30: require('../assets/images/google-30x30.svg').default,
  Microsoft30x30: require('../assets/images/microsoft-30x30.svg').default,
  Server30x30: require('../assets/images/server-30x30.svg').default,
  Ellipse1: require('../assets/images/ellips-1.svg').default,
  Ellipse2: require('../assets/images/ellips-2.svg').default,
  Ellipse3: require('../assets/images/ellips-3.svg').default,
  Ellipse4: require('../assets/images/ellips-4.svg').default,
  HalfCircle: require('../assets/images/half-circle.svg').default,
  Friends: require('../assets/images/friends.svg').default,
  FriendsBlue: require('../assets/images/friends-blue.svg').default,
  NoEmailsFound: require('../assets/images/no-email-found.svg').default,
  Popper: require('../assets/images/popper.svg').default,
  Messages: require('../assets/images/messages.svg').default,
  Default: require('../assets/images/default.svg').default,
  Image: require('../assets/images/image.svg').default,
  Zip: require('../assets/images/zip.svg').default,
  Pdf: require('../assets/images/pdf.svg').default,
  Video: require('../assets/images/video.svg').default,
  Ppt: require('../assets/images/ppt.svg').default,
  Sheets: require('../assets/images/sheets.svg').default,
  Docs: require('../assets/images/docs.svg').default,
  Rotate: require('../assets/images/rotate.svg').default,
  Star: require('../assets/images/star.svg').default,
  Discount: require('../assets/images/discount.svg').default,
  PaperPlane: require('../assets/images/paper-plane.svg').default,
  PausedWithBackground: require('../assets/images/paused-with-bg.svg').default,
  ResumeWithBackground: require('../assets/images/resume-with-bg.svg').default,
  CircleCheckGreen: require('../assets/images/circle-check-green.svg').default,
  AlertTriangleOrange: require('../assets/images/alert-triangle-orange.svg')
    .default,
  TrulyInbox: require('../assets/images/truly-inbox.svg').default,
  TrulyInboxLogo: require('../assets/images/TrulyInbox_Logo.svg').default,
  SendColdEmails: require('../assets/images/send-cold-emails.svg').default,
  SaleshandyLogo: require('../assets/images/saleshandy.svg').default,
  SwitchHorizontal: require('../assets/images/switch-horizontal.svg').default,
  CSV: require('../assets/images/csv.svg').default,
  ClockFastForward: require('../assets/images/clock-fast-forward.svg').default,
};
