/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { UserSetting, ProfileProgress } from '../../shared/types/user-setting';
import { FeatureUsage } from '../../shared/types/feature-usage';
import { Sequence } from '../sequence/types';
import {
  getFirstSequenceRequest,
  getSampleSequenceRequest,
  getUserSettingsRequest,
  updateUserSettingsRequest,
  upgradeOnboardingStepRequest,
  getUserNotificationsRequest,
  getUserUnreadNotificationsRequest,
  markNotificationsAsReadRequest,
  createSequenceFromGettingStartedRequest,
  compareAuthTokenRequest,
  getAgencyConfigRequest,
  getPostLoadMetaRequest,
  extendTrialPeriodRequest,
} from './extra-actions';
import updateProfileProgressRequest from '../../shared/components/getting-started-onboarding/extra-actions';
import {
  AgencyConfig,
  GetPostLoadMetaResponse,
  Notifications,
  SubscriptionDetails,
} from './types';
import { IcpStatus } from '../../shared/enums/icp-status';
import { defaultAgencyObj } from './utils/helper';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type ApplicationState = {
  showActivateSequenceTooltip?: boolean;
  showAddEmailAccountModal?: boolean;
  hasUserConnectedEmailAccount?: boolean;
  loggedUserEmail?: string;
  shouldLogoutUser?: boolean;
  logoURL?: string;
  hasSignUpCompleted?: boolean;
  helpscoutBeaconId?: string;
};

type AdminDetails = {
  email: string;
  firstName: string;
  lastName: string;
};

type State = {
  getUserSettingsRequest: RequestState;
  getAgencyConfigRequest: RequestState;
  getFirstSequenceRequest: RequestState;
  getSampleSequenceRequest: RequestState;
  updateUserSettingsRequest: RequestState;
  upgradeOnboardingStepRequest: RequestState;
  updateProfileProgressRequest: RequestState;
  createSequenceFromGettingStartedRequest: RequestState;
  getUserUnreadNotificationsRequest: RequestState;
  compareAuthTokenRequest: RequestState;
  getPostLoadMetaRequest: RequestState;
  extendTrialPeriodRequest: RequestState;
  meta: UserSetting[];
  profileProgress: ProfileProgress[];
  gettingStatedCreatedSequenceId: number;
  email: string;
  firstName: string;
  lastName: string;
  trackingId: string;
  firstSequence: Sequence;
  subscription: SubscriptionDetails;
  notifications: Notifications[];
  notificationCount: number;
  role: string;
  featureUsages: FeatureUsage[];
  permissions: string[];
  resources: string[];
  team_size: string;
  designation: string;
  signup_date: string;
  markNotificationsAsReadRequest: RequestState;
  application: ApplicationState;
  adminDetails: AdminDetails;
  churnkeyHash: string;
  agencyConfig: AgencyConfig;
  userHasUnreadNotification: boolean;
  showYoutubeVideModal: boolean;
  icpStatus: IcpStatus;
  getPostLoadMetaResponse: GetPostLoadMetaResponse;
  createdAt: Date;
  isEligibleToExtendTrial: boolean;
  userId: number;
  syncConsent: boolean;
};

const initialState: State = {
  getAgencyConfigRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  markNotificationsAsReadRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getUserSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getFirstSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSampleSequenceRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateUserSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  upgradeOnboardingStepRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateProfileProgressRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createSequenceFromGettingStartedRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getUserUnreadNotificationsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  compareAuthTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getPostLoadMetaRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  extendTrialPeriodRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  meta: null,
  profileProgress: null,
  email: null,
  firstName: null,
  lastName: null,
  trackingId: null,
  firstSequence: null,
  subscription: null,
  notifications: [],
  notificationCount: 0,
  role: null,
  application: {
    showActivateSequenceTooltip: false,
    showAddEmailAccountModal: false,
    hasUserConnectedEmailAccount: false,
    loggedUserEmail: '',
    shouldLogoutUser: false,
    logoURL: '',
    hasSignUpCompleted: false,
  },
  featureUsages: [],
  permissions: [],
  resources: [],
  team_size: null,
  designation: null,
  signup_date: null,
  gettingStatedCreatedSequenceId: null,
  adminDetails: null,
  churnkeyHash: null,
  agencyConfig: defaultAgencyObj,
  userHasUnreadNotification: null,
  showYoutubeVideModal: null,
  icpStatus: null,
  getPostLoadMetaResponse: null,
  createdAt: null,
  isEligibleToExtendTrial: false,
  userId: null,
  syncConsent: false,
};

type ReadNotification = {
  notificationId?: string;
};

const homeSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    markNotificationAsRead: (
      state,
      action: PayloadAction<ReadNotification>,
    ) => {
      const { notificationId } = action.payload;

      if (notificationId) {
        state.notifications = state.notifications.map((notification) => {
          // eslint-disable-next-line no-underscore-dangle
          if (notification._id === notificationId) {
            notification.hasRead = true;
          }
          return notification;
        });
      } else {
        state.notifications = state.notifications.map((notification) => ({
          ...notification,
          hasRead: true,
        }));
        state.userHasUnreadNotification = null;
      }
    },
    updateApplicationState: (
      state,
      action: PayloadAction<ApplicationState>,
    ) => {
      state.application = { ...state.application, ...action.payload };
    },
    showGettingStartedVideoModal: (
      state,
      action: PayloadAction<{ show: boolean }>,
    ) => {
      state.showYoutubeVideModal = action.payload.show;
    },
    resetFirstSequenceData: (state) => {
      state.firstSequence = null;
    },
    resetCompareAuthTokenRequest: (state) => {
      state.createSequenceFromGettingStartedRequest =
        initialState.compareAuthTokenRequest;
    },
    resetApplicationState: (state) => {
      state.application.hasSignUpCompleted = false;
    },
  },
  extraReducers: (builder) => {
    // Get Color Palette And Logo
    builder.addCase(getAgencyConfigRequest.pending, (state) => {
      state.getAgencyConfigRequest.status = RequestStatus.Pending;
      state.getAgencyConfigRequest.error = null;
    });
    builder.addCase(getAgencyConfigRequest.fulfilled, (state, action) => {
      state.getAgencyConfigRequest.status = RequestStatus.Succeeded;
      state.getAgencyConfigRequest.message = action.payload.message;
      state.agencyConfig = action.payload.payload;
    });
    builder.addCase(getAgencyConfigRequest.rejected, (state, action) => {
      state.getAgencyConfigRequest.status = RequestStatus.Failed;
      state.getAgencyConfigRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get user settings
    builder.addCase(getUserSettingsRequest.pending, (state) => {
      state.getUserSettingsRequest.status = RequestStatus.Pending;
      state.getUserSettingsRequest.error = null;
    });
    builder.addCase(getUserSettingsRequest.fulfilled, (state, action) => {
      state.getUserSettingsRequest.status = RequestStatus.Succeeded;
      state.getUserSettingsRequest.message = action.payload.message;
      state.meta = action.payload.payload.meta
        ? action.payload.payload.meta
        : [];
      state.profileProgress = action.payload.payload.profileProgress;
      state.subscription = action.payload.payload.account?.subscription;

      if (state.subscription) {
        state.subscription.nextBillingAt = state.subscription?.nextBillingAt?.replace(
          /-/g,
          '/',
        );
        state.subscription.startAt = state.subscription?.startAt?.replace(
          /-/g,
          '/',
        );
      }
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.trackingId = action.payload.payload.trackingId;
      state.email = action.payload.payload.email;
      state.role = action.payload.payload.role;
      state.churnkeyHash = action.payload.payload.churnkeyHash;
      state.featureUsages = action.payload.payload.featureUsages;
      state.permissions = action.payload.payload.permissions;
      state.resources = action.payload.payload.resources;
      state.adminDetails = action.payload.payload.adminDetails;
      state.team_size = action.payload.payload.team_size;
      state.designation = action.payload.payload.designation;
      state.signup_date = action.payload.payload.signup_date;
      state.icpStatus = action.payload.payload.icpStatus;
      state.createdAt = action.payload.payload.createdAt;
      state.syncConsent = action.payload.payload.syncConsent;
      state.isEligibleToExtendTrial =
        action.payload.payload.isEligibleToExtendTrial;
      state.userId = action.payload.payload.userId;
    });
    builder.addCase(getUserSettingsRequest.rejected, (state, action) => {
      state.getUserSettingsRequest.status = RequestStatus.Failed;
      state.getUserSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get first sequence
    builder.addCase(getFirstSequenceRequest.pending, (state) => {
      state.getFirstSequenceRequest.status = RequestStatus.Pending;
      state.getFirstSequenceRequest.error = null;
    });
    builder.addCase(getFirstSequenceRequest.fulfilled, (state, action) => {
      state.getFirstSequenceRequest.status = RequestStatus.Succeeded;
      state.getFirstSequenceRequest.message = action.payload.message;
      state.firstSequence = action.payload.payload;
    });
    builder.addCase(getFirstSequenceRequest.rejected, (state, action) => {
      state.getFirstSequenceRequest.status = RequestStatus.Failed;
      state.getFirstSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Post Load Meta Request
    builder.addCase(getPostLoadMetaRequest.pending, (state) => {
      state.getPostLoadMetaRequest.status = RequestStatus.Pending;
      state.getPostLoadMetaRequest.error = null;
    });
    builder.addCase(getPostLoadMetaRequest.fulfilled, (state, action) => {
      state.getPostLoadMetaRequest.status = RequestStatus.Succeeded;
      state.getPostLoadMetaResponse = action.payload.payload;
      state.getPostLoadMetaRequest.message = action.payload.message;
    });
    builder.addCase(getPostLoadMetaRequest.rejected, (state, action) => {
      state.getPostLoadMetaRequest.status = RequestStatus.Failed;
      state.getPostLoadMetaRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get sample sequence
    builder.addCase(getSampleSequenceRequest.pending, (state) => {
      state.getSampleSequenceRequest.status = RequestStatus.Pending;
      state.getSampleSequenceRequest.error = null;
    });
    builder.addCase(getSampleSequenceRequest.fulfilled, (state, action) => {
      state.getSampleSequenceRequest.status = RequestStatus.Succeeded;
      state.getSampleSequenceRequest.message = action.payload.message;
      state.firstSequence = action.payload.payload;
    });
    builder.addCase(getSampleSequenceRequest.rejected, (state, action) => {
      state.getSampleSequenceRequest.status = RequestStatus.Failed;
      state.getSampleSequenceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update user settings
    builder.addCase(updateUserSettingsRequest.pending, (state) => {
      state.updateUserSettingsRequest.status = RequestStatus.Pending;
      state.updateUserSettingsRequest.error = null;
    });
    builder.addCase(updateUserSettingsRequest.fulfilled, (state, action) => {
      state.updateUserSettingsRequest.status = RequestStatus.Succeeded;
      state.updateUserSettingsRequest.message = action.payload.message;
    });
    builder.addCase(updateUserSettingsRequest.rejected, (state, action) => {
      state.updateUserSettingsRequest.status = RequestStatus.Failed;
      state.updateUserSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Upgrade Onboarding Step
    builder.addCase(upgradeOnboardingStepRequest.pending, (state) => {
      state.upgradeOnboardingStepRequest.status = RequestStatus.Pending;
      state.upgradeOnboardingStepRequest.error = null;
    });
    builder.addCase(upgradeOnboardingStepRequest.fulfilled, (state, action) => {
      state.upgradeOnboardingStepRequest.status = RequestStatus.Succeeded;
      state.upgradeOnboardingStepRequest.message = action.payload.message;
      state.meta = action.payload.payload.meta;
    });
    builder.addCase(upgradeOnboardingStepRequest.rejected, (state, action) => {
      state.upgradeOnboardingStepRequest.status = RequestStatus.Failed;
      state.upgradeOnboardingStepRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Steps Progress Status
    builder.addCase(updateProfileProgressRequest.pending, (state) => {
      state.updateProfileProgressRequest.status = RequestStatus.Pending;
      state.updateProfileProgressRequest.error = null;
    });
    builder.addCase(updateProfileProgressRequest.fulfilled, (state, action) => {
      state.updateProfileProgressRequest.status = RequestStatus.Succeeded;
      state.updateProfileProgressRequest.message = action.payload.message;
    });
    builder.addCase(updateProfileProgressRequest.rejected, (state, action) => {
      state.updateProfileProgressRequest.status = RequestStatus.Failed;
      state.updateProfileProgressRequest.error = action.payload;
    });

    // Compare Auth Tokens
    builder.addCase(compareAuthTokenRequest.pending, (state) => {
      state.compareAuthTokenRequest.status = RequestStatus.Pending;
      state.compareAuthTokenRequest.error = null;
    });
    builder.addCase(compareAuthTokenRequest.fulfilled, (state, action) => {
      state.compareAuthTokenRequest.status = RequestStatus.Succeeded;
      state.compareAuthTokenRequest.message = action.payload.message;
      state.application.loggedUserEmail = action.payload.payload.email;
      state.application.shouldLogoutUser =
        action.payload.payload.shouldLogoutUser;
    });
    builder.addCase(compareAuthTokenRequest.rejected, (state, action) => {
      state.compareAuthTokenRequest.status = RequestStatus.Failed;
      state.compareAuthTokenRequest.error = action.payload;
    });

    // Create Sequence Status
    builder.addCase(
      createSequenceFromGettingStartedRequest.pending,
      (state) => {
        state.createSequenceFromGettingStartedRequest.status =
          RequestStatus.Pending;
        state.createSequenceFromGettingStartedRequest.error = null;
      },
    );
    builder.addCase(
      createSequenceFromGettingStartedRequest.fulfilled,
      (state, action) => {
        state.createSequenceFromGettingStartedRequest.status =
          RequestStatus.Succeeded;
        state.createSequenceFromGettingStartedRequest.message =
          action.payload.message;
        state.gettingStatedCreatedSequenceId = action.payload.payload.id;
      },
    );
    builder.addCase(
      createSequenceFromGettingStartedRequest.rejected,
      (state, action) => {
        state.createSequenceFromGettingStartedRequest.status =
          RequestStatus.Failed;
        state.createSequenceFromGettingStartedRequest.error = action.payload;
      },
    );

    // User notifications
    builder.addCase(getUserNotificationsRequest.fulfilled, (state, action) => {
      state.notifications = [
        ...state.notifications,
        ...action.payload.payload.notifications,
      ];
      state.notificationCount = action.payload.payload.count;
    });

    // Marking notifications as read
    builder.addCase(markNotificationsAsReadRequest.fulfilled, (state) => {
      state.markNotificationsAsReadRequest.status = RequestStatus.Succeeded;
    });

    builder.addCase(markNotificationsAsReadRequest.rejected, (state) => {
      state.markNotificationsAsReadRequest.status = RequestStatus.Failed;
    });

    // Get user unread notification
    builder.addCase(
      getUserUnreadNotificationsRequest.fulfilled,
      (state, action) => {
        state.getUserUnreadNotificationsRequest.status =
          RequestStatus.Succeeded;
        state.userHasUnreadNotification = action.payload.payload;
      },
    );

    builder.addCase(getUserUnreadNotificationsRequest.rejected, (state) => {
      state.getUserUnreadNotificationsRequest.status = RequestStatus.Failed;
    });

    // Extend Trial Period
    builder.addCase(extendTrialPeriodRequest.pending, (state) => {
      state.extendTrialPeriodRequest.status = RequestStatus.Pending;
    });
    builder.addCase(extendTrialPeriodRequest.fulfilled, (state) => {
      state.extendTrialPeriodRequest.status = RequestStatus.Succeeded;
    });

    builder.addCase(extendTrialPeriodRequest.rejected, (state, action) => {
      state.extendTrialPeriodRequest.status = RequestStatus.Failed;
      state.extendTrialPeriodRequest.error = action.payload;
    });
  },
});

// email | web-app

export {
  getUserSettingsRequest,
  updateUserSettingsRequest,
  upgradeOnboardingStepRequest,
  updateProfileProgressRequest,
  getSampleSequenceRequest,
};
export const {
  markNotificationAsRead,
  updateApplicationState,
  showGettingStartedVideoModal,
  resetFirstSequenceData,
  resetCompareAuthTokenRequest,
  resetApplicationState,
} = homeSlice.actions;
export default homeSlice.reducer;
