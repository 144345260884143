import React, { useEffect } from 'react';

import Modal from '../../../../shared/design-system/components/atoms/modal';

import type { IProps } from './types';
import { Cross } from '../../../../shared/svg';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import Input from '../../../../shared/design-system/components/input/input';
import Button from '../../../../shared/design-system/components/atoms/button';
import { SkeletonLoading } from '@saleshandy/design-system';
import { getSaleshandyApiKey } from '../../helpers/email-warmup.api';
import {
  OverlayPopover,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import { Help } from '@saleshandy/icons';

const SaleshandyEmailConnectModal: React.FC<IProps> = ({
  show,
  onClose,
  onApply,
  isLoading,
}) => {
  const [apiKey, setApiKey] = React.useState('');
  const [prefillData, setPrefillData] = React.useState<any>({});
  const [getShApiKeyLoading, setGetShApiKeyLoading] = React.useState(false);

  useEffect(() => {
    const fetchSaleshandyApiKey = async () => {
      try {
        setGetShApiKeyLoading(true);
        const saleshandyApiKey = await getSaleshandyApiKey();
        const data = saleshandyApiKey?.payload || {};
        if (data) {
          setPrefillData(data);
        }
      } catch (error) {
        setPrefillData({});
      } finally {
        setGetShApiKeyLoading(false);
      }
    };

    fetchSaleshandyApiKey();
  }, []);

  const [syncLearnMore, setSyncLearnMore] = React.useState(false);

  return (
    <Modal
      show={show}
      onClose={onClose}
      onSubmit={() => onApply(apiKey)}
      submitButtonText="Apply"
      cancelButtonText="Discard"
      isSubmitDisabled={
        apiKey?.length <= 0 ||
        getShApiKeyLoading ||
        prefillData?.apiKey?.length > 0
      }
      cancelButtonVarient={Button.Variant.Outlined}
      isSubmitLoading={isLoading}
      backdrop="static"
      hideHeader
      className="saleshandy-email-account-modal"
    >
      <div className="saleshandy-email-account-modal__header">
        <div className="header-inner">
          <h1>Get Email Accounts</h1>
        </div>
        <div className="close-btn" {...accessibleOnClick(onClose)}>
          <Cross />
        </div>
      </div>
      <div className="saleshandy-email-account-modal__desc">
        <div className="form-item">
          {getShApiKeyLoading ? (
            <SkeletonLoading height={36} width={340} />
          ) : (
            <div className="d-flex">
              <Input
                label={
                  <p>
                    {' '}
                    Saleshandy API Key{' '}
                    <OverlayPopover
                      className="permission-popover"
                      show={syncLearnMore}
                      onToggle={(showPopover: boolean) =>
                        setSyncLearnMore(showPopover)
                      }
                      placement={Placement.Right}
                      content={
                        <>
                          {prefillData?.apiKey?.length > 0 &&
                          prefillData?.email?.length > 0 ? (
                            <div className="content">
                              Your account is connected with{' '}
                              <span>{prefillData?.email}</span>
                            </div>
                          ) : (
                            <div className="content">
                              Learn to get Saleshandy API key from{' '}
                              <a
                                href={
                                  'https://docs.saleshandy.com/en/articles/8154265-saleshandy-api'
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                here
                              </a>
                              .
                            </div>
                          )}
                          <div
                            className="d-flex justify-content-center icon"
                            {...accessibleOnClick(() =>
                              setSyncLearnMore(false),
                            )}
                          >
                            <Cross />
                          </div>
                        </>
                      }
                    >
                      <Help className="permission-popover-btn cursor-pointer" />
                    </OverlayPopover>{' '}
                  </p>
                }
                placeholder="Enter here"
                className="form-control-input"
                value={
                  prefillData?.apiKey?.length > 0 ? prefillData?.apiKey : apiKey
                }
                onChange={(e) => setApiKey(e)}
                disabled={prefillData?.apiKey?.length > 0}
              />
            </div>
          )}
        </div>
      </div>
      <div className="saleshandy-email-account-modal__content">
        <h3 className="title">What Next?</h3>
        <div className="list-items">
          <ul>
            <li>
              Once your API key from Saleshandy is verified, you will be
              redirected to same Saleshandy account.
            </li>
            <li>
              In Saleshandy Email Account Tab, you will be asked to give consent
              for Credential Sharing.
            </li>
            <li>
              After successful authorization, your all email accounts will be
              synced with Trulyinbox. All future emails connection will also be
              added.
            </li>
          </ul>
        </div>
      </div>

      <div className="connect-option-wrapper d-flex align-items-center" />
    </Modal>
  );
};

export default SaleshandyEmailConnectModal;
