export enum EmailAccountWarmupSortKey {
  Deliverability = 'deliverability',
  SentToday = 'sent-today',
  ReceivedToday = 'received-today',
}

export enum EmailAccountWarmupOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EmailWarmupStatus {
  Stopped = 0,
  Running = 1,
  Suspended = 2,
  Paused = 3,
}

export enum ErrorMessage {
  DefaultObjObjError = '"[object Object]"',
  DisplayErrorMessage = 'Please contact our support team at support@trulyinbox.com',
}

export enum EmailAccountsFilters {
  Tags = 'tags',
  Status = 'status',
  CreatedDate = 'created date',
}

export enum EmailAccountStatus {
  Active = '1',
  Stop = '0',
  Pause = '3',
  Blocked = '2',
}

export enum EmailAccountStatusTypeDisplayName {
  Active = 'Running',
  Stop = 'Stopped',
  Pause = 'Paused',
  Blocked = 'Suspended',
}

export enum EmailAccountTags {
  None = 0,
}

export enum EmailAccountTagsTyeDisplayName {
  None = 'Without Tags',
}
