import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  getEmailAccountsInWarmupRequest,
  getEmailAccountWarmupDeliverabilityReportRequest,
  getEmailAccountWarmupSentReceivedReportRequest,
  getEmailAccountWarmupSettingsRequest,
  saveEmailAccountWarmupSettingsRequest,
  turnOnOffEmailAccountWarmupRequest,
  getEmailAccountWarmupListRequest,
  importEmailAccountCsvRequest,
  syncSaleshandyEmailAccountWithApiKeyRequest,
  getEmailAccountsTagsRequest,
  emailAccountTagsAssignRequest,
  emailAccountTagsBulkAssignRequest,
  emailAccountTagsUnassignRequest,
  emailAccountTagsBulkUnassignRequest,
  saveBulkEmailAccountWarmupSettingsRequest,
  updateAllEmailAccountWarmupSettingsRequest,
} from './extra-actions';
import {
  EmailAccountTag,
  EmailAccountFilters,
  EmailAccountWarmupDeliverabilityReport,
  EmailAccountWarmupFilterDropdowns,
  EmailAccountWarmupFilters,
  EmailAccountWarmupList,
  EmailAccountWarmupSentReceivedReport,
  EmailAccountWarmupSettings,
  EmailWarmupAccount,
} from './types';
import { getEmailAccountsFiltersFromLocalStore } from './helpers/email-filters-resolver';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

export const emailAccountFiltersInitialState: EmailAccountWarmupFilters = {
  tags: [],
  status: [],
  warmupStartDateFrom: null,
  warmupStartDateTo: null,
};

export const warmupEmailAccountInitialState: EmailAccountFilters = {
  tags: [],
  status: [],
  'created date': [],
};

type State = {
  getEmailAccountsInWarmupRequest: RequestState;
  getEmailAccountWarmupDeliverabilityReportRequest: RequestState;
  getEmailAccountWarmupSentReceivedReportRequest: RequestState;
  getEmailAccountWarmupSettingsRequest: RequestState;
  saveEmailAccountWarmupSettingsRequest: RequestState;
  turnOnOffEmailAccountWarmupRequest: RequestState;
  getEmailAccountWarmupListRequest: RequestState;
  importEmailAccountCsvRequest: RequestState;
  syncSaleshandyEmailAccountWithApiKeyRequest: RequestState;
  getEmailAccountsTagsRequest: RequestState;
  emailAccountTagsAssignRequest: RequestState;
  emailAccountTagsBulkAssignRequest: RequestState;
  emailAccountTagsUnassignRequest: RequestState;
  emailAccountTagsBulkUnassignRequest: RequestState;
  saveBulkEmailAccountWarmupSettingsRequest: RequestState;
  updateAllEmailAccountWarmupSettingsRequest: RequestState;
  emailWarmupAccounts: EmailWarmupAccount[];
  emailAccountWarmupSentReceivedReport: EmailAccountWarmupSentReceivedReport;
  emailAccountWarmupDeliverabilityReport: EmailAccountWarmupDeliverabilityReport;
  emailAccountWarmupSettings: EmailAccountWarmupSettings;
  emailAccountWarmupList: EmailAccountWarmupList[];
  totalEmailWarmupAccounts: number;
  emailAccountsFilters: EmailAccountWarmupFilters;
  warmupEmailAccountFilter: EmailAccountWarmupFilterDropdowns;
  selectedEmailWarmupAccounts: EmailWarmupAccount[];
  emailAccountTagsList: EmailAccountTag[];

  selectedEmails: any[];
  allSelectedEmailsDetails: {
    isAllProspectsSelected?: boolean;
    deSelectedContactIds?: any[];
  };
};

const initialState: State = {
  getEmailAccountsInWarmupRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupDeliverabilityReportRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupSentReceivedReportRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  turnOnOffEmailAccountWarmupRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountWarmupListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  importEmailAccountCsvRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  syncSaleshandyEmailAccountWithApiKeyRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getEmailAccountsTagsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsBulkAssignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsUnassignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailAccountTagsBulkUnassignRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  saveBulkEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAllEmailAccountWarmupSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  emailWarmupAccounts: [],
  totalEmailWarmupAccounts: 0,
  emailAccountWarmupSentReceivedReport: null,
  emailAccountWarmupDeliverabilityReport: null,
  emailAccountWarmupSettings: null,
  emailAccountWarmupList: null,
  emailAccountsFilters:
    getEmailAccountsFiltersFromLocalStore() || emailAccountFiltersInitialState,
  warmupEmailAccountFilter: warmupEmailAccountInitialState,
  selectedEmailWarmupAccounts: [],
  emailAccountTagsList: [],
  selectedEmails: [],
  allSelectedEmailsDetails: null,
};

const emailWarmupSlice = createSlice({
  name: 'emailWarmup',
  initialState,
  reducers: {
    resetEmailWarmupState: (state) => {
      state.getEmailAccountsInWarmupRequest =
        initialState.getEmailAccountsInWarmupRequest;
      state.getEmailAccountWarmupDeliverabilityReportRequest =
        initialState.getEmailAccountWarmupDeliverabilityReportRequest;
      state.getEmailAccountWarmupSentReceivedReportRequest =
        initialState.getEmailAccountWarmupSentReceivedReportRequest;
      state.getEmailAccountWarmupSettingsRequest =
        initialState.getEmailAccountWarmupSettingsRequest;
      state.saveEmailAccountWarmupSettingsRequest =
        initialState.saveEmailAccountWarmupSettingsRequest;
      state.turnOnOffEmailAccountWarmupRequest =
        initialState.turnOnOffEmailAccountWarmupRequest;
      state.getEmailAccountWarmupListRequest =
        initialState.getEmailAccountWarmupListRequest;
      state.getEmailAccountsTagsRequest =
        initialState.getEmailAccountsTagsRequest;
      state.emailAccountTagsAssignRequest =
        initialState.emailAccountTagsAssignRequest;
      state.emailAccountTagsBulkAssignRequest =
        initialState.emailAccountTagsBulkAssignRequest;
      state.emailAccountTagsUnassignRequest =
        initialState.emailAccountTagsUnassignRequest;
      state.emailAccountTagsBulkUnassignRequest =
        initialState.emailAccountTagsBulkUnassignRequest;
      state.emailWarmupAccounts = initialState.emailWarmupAccounts;
      state.emailAccountWarmupSentReceivedReport =
        initialState.emailAccountWarmupSentReceivedReport;
      state.emailAccountWarmupDeliverabilityReport =
        initialState.emailAccountWarmupDeliverabilityReport;
      state.emailAccountWarmupSettings =
        initialState.emailAccountWarmupSettings;
      state.emailAccountWarmupList = initialState.emailAccountWarmupList;
      state.totalEmailWarmupAccounts = initialState.totalEmailWarmupAccounts;
      state.selectedEmailWarmupAccounts =
        initialState.selectedEmailWarmupAccounts;
      state.emailAccountTagsList = initialState.emailAccountTagsList;
    },
    resetTurnOnOffEmailAccountWarmupState: (state) => {
      state.turnOnOffEmailAccountWarmupRequest =
        initialState.turnOnOffEmailAccountWarmupRequest;
    },
    setEmailAccountsFilters: (state, action) => {
      state.emailAccountsFilters = { ...action.payload };
    },
    setWarmupEmailAccountFilters: (state, action) => {
      state.warmupEmailAccountFilter = { ...action.payload };
    },
    checkSingle: (state, action) => {
      if (action.payload.status === true) {
        if (state.allSelectedEmailsDetails?.isAllProspectsSelected) {
          state.allSelectedEmailsDetails.deSelectedContactIds = state.allSelectedEmailsDetails.deSelectedContactIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedEmails = state.selectedEmails.concat([
          action.payload.row,
        ]);
      } else {
        if (state.allSelectedEmailsDetails?.isAllProspectsSelected) {
          state.allSelectedEmailsDetails.deSelectedContactIds = state.allSelectedEmailsDetails?.deSelectedContactIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedEmails = state.selectedEmails.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    checkAll: (state, action) => {
      if (action.payload.status === true) {
        if (state.allSelectedEmailsDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.allSelectedEmailsDetails.deSelectedContactIds = state.allSelectedEmailsDetails.deSelectedContactIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedEmails = state.selectedEmails.concat(action.payload.rows);
      } else {
        if (state.allSelectedEmailsDetails?.isAllProspectsSelected) {
          action.payload.rows.forEach(({ id }) => {
            state.allSelectedEmailsDetails.deSelectedContactIds = state.allSelectedEmailsDetails?.deSelectedContactIds.concat(
              [id],
            );
          });
        }
        state.selectedEmails = state.selectedEmails.reduce((acc, item) => {
          let isPresent = false;
          action.payload.rows.forEach(({ id }) => {
            if (id === item.id) {
              isPresent = true;
            }
          });
          if (isPresent) {
            return acc;
          }
          return [...acc, item];
        }, []);
      }
    },
    clearSelection: (state) => {
      state.allSelectedEmailsDetails = initialState.allSelectedEmailsDetails;
      state.selectedEmails = initialState.selectedEmails;
      state.emailAccountTagsAssignRequest =
        initialState.emailAccountTagsAssignRequest;
      state.emailAccountTagsBulkAssignRequest =
        initialState.emailAccountTagsBulkAssignRequest;
      state.emailAccountTagsUnassignRequest =
        initialState.emailAccountTagsUnassignRequest;
      state.emailAccountTagsBulkUnassignRequest =
        initialState.emailAccountTagsBulkUnassignRequest;
      state.saveBulkEmailAccountWarmupSettingsRequest =
        initialState.saveBulkEmailAccountWarmupSettingsRequest;
      state.updateAllEmailAccountWarmupSettingsRequest =
        initialState.updateAllEmailAccountWarmupSettingsRequest;
    },
    selectAllContacts: (state, action) => {
      state.allSelectedEmailsDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Email Account List In Warmup
    builder.addCase(getEmailAccountsInWarmupRequest.pending, (state) => {
      state.getEmailAccountsInWarmupRequest.status = RequestStatus.Pending;
      state.getEmailAccountsInWarmupRequest.error = null;
      state.getEmailAccountsInWarmupRequest.message = null;
    });
    builder.addCase(
      getEmailAccountsInWarmupRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountsInWarmupRequest.status = RequestStatus.Succeeded;
        state.emailWarmupAccounts = action.payload.payload.emailAccounts;
        state.totalEmailWarmupAccounts =
          action.payload.payload.emailAccountCount;
      },
    );
    builder.addCase(
      getEmailAccountsInWarmupRequest.rejected,
      (state, action) => {
        state.getEmailAccountsInWarmupRequest.status = RequestStatus.Failed;
        state.getEmailAccountsInWarmupRequest.message = action.payload.message;
        state.getEmailAccountsInWarmupRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Deliverability Report
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.pending,
      (state) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Pending;
        state.getEmailAccountWarmupDeliverabilityReportRequest.error = null;
        state.getEmailAccountWarmupDeliverabilityReportRequest.message = null;
      },
    );
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupDeliverabilityReport = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupDeliverabilityReportRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupDeliverabilityReportRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupDeliverabilityReportRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupDeliverabilityReportRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Sent Received Report
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.pending,
      (state) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Pending;
        state.getEmailAccountWarmupSentReceivedReportRequest.error = null;
        state.getEmailAccountWarmupSentReceivedReportRequest.message = null;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupSentReceivedReport = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSentReceivedReportRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupSentReceivedReportRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupSentReceivedReportRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupSentReceivedReportRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Email Account Warmup Settings
    builder.addCase(getEmailAccountWarmupSettingsRequest.pending, (state) => {
      state.getEmailAccountWarmupSettingsRequest.status = RequestStatus.Pending;
      state.getEmailAccountWarmupSettingsRequest.error = null;
      state.getEmailAccountWarmupSettingsRequest.message = null;
    });
    builder.addCase(
      getEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountWarmupSettings = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.getEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.getEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Save Email Account Warmup Settings
    builder.addCase(saveEmailAccountWarmupSettingsRequest.pending, (state) => {
      state.saveEmailAccountWarmupSettingsRequest.status =
        RequestStatus.Pending;
      state.saveEmailAccountWarmupSettingsRequest.error = null;
      state.saveEmailAccountWarmupSettingsRequest.message = null;
    });
    builder.addCase(
      saveEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.saveEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.saveEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      saveEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.saveEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.saveEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.saveEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Save Bulk Email Account Warmup Settings
    builder.addCase(
      saveBulkEmailAccountWarmupSettingsRequest.pending,
      (state) => {
        state.saveBulkEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Pending;
        state.saveBulkEmailAccountWarmupSettingsRequest.error = null;
        state.saveBulkEmailAccountWarmupSettingsRequest.message = null;
      },
    );
    builder.addCase(
      saveBulkEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.saveBulkEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.saveBulkEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      saveBulkEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.saveBulkEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.saveBulkEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.saveBulkEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Save Bulk All Email Account Warmup Settings
    builder.addCase(
      updateAllEmailAccountWarmupSettingsRequest.pending,
      (state) => {
        state.updateAllEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Pending;
        state.updateAllEmailAccountWarmupSettingsRequest.error = null;
        state.updateAllEmailAccountWarmupSettingsRequest.message = null;
      },
    );
    builder.addCase(
      updateAllEmailAccountWarmupSettingsRequest.fulfilled,
      (state, action) => {
        state.updateAllEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Succeeded;
        state.updateAllEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateAllEmailAccountWarmupSettingsRequest.rejected,
      (state, action) => {
        state.updateAllEmailAccountWarmupSettingsRequest.status =
          RequestStatus.Failed;
        state.updateAllEmailAccountWarmupSettingsRequest.message =
          action.payload.message;
        state.updateAllEmailAccountWarmupSettingsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Turn On/Off Email Account Warmup Settings
    builder.addCase(turnOnOffEmailAccountWarmupRequest.pending, (state) => {
      state.turnOnOffEmailAccountWarmupRequest.status = RequestStatus.Pending;
      state.turnOnOffEmailAccountWarmupRequest.error = null;
      state.turnOnOffEmailAccountWarmupRequest.message = null;
    });
    builder.addCase(
      turnOnOffEmailAccountWarmupRequest.fulfilled,
      (state, action) => {
        state.turnOnOffEmailAccountWarmupRequest.status =
          RequestStatus.Succeeded;
        state.turnOnOffEmailAccountWarmupRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      turnOnOffEmailAccountWarmupRequest.rejected,
      (state, action) => {
        state.turnOnOffEmailAccountWarmupRequest.status = RequestStatus.Failed;
        state.turnOnOffEmailAccountWarmupRequest.message =
          action.payload.message;
        state.turnOnOffEmailAccountWarmupRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Email Account Warmup List
    builder.addCase(getEmailAccountWarmupListRequest.pending, (state) => {
      state.getEmailAccountWarmupListRequest.status = RequestStatus.Pending;
      state.getEmailAccountWarmupListRequest.error = null;
      state.getEmailAccountWarmupListRequest.message = null;
    });
    builder.addCase(
      getEmailAccountWarmupListRequest.fulfilled,
      (state, action) => {
        state.getEmailAccountWarmupListRequest.status = RequestStatus.Succeeded;
        state.emailAccountWarmupList = action.payload.payload;
      },
    );
    builder.addCase(
      getEmailAccountWarmupListRequest.rejected,
      (state, action) => {
        state.getEmailAccountWarmupListRequest.status = RequestStatus.Failed;
        state.getEmailAccountWarmupListRequest.message = action.payload.message;
        state.getEmailAccountWarmupListRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Import Email Accounts Via CSV
    builder.addCase(importEmailAccountCsvRequest.pending, (state) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Pending;
      state.importEmailAccountCsvRequest.message = null;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.fulfilled, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Succeeded;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error = null;
    });
    builder.addCase(importEmailAccountCsvRequest.rejected, (state, action) => {
      state.importEmailAccountCsvRequest.status = RequestStatus.Failed;
      state.importEmailAccountCsvRequest.message = action.payload.message;
      state.importEmailAccountCsvRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Saleshandy email account sync
    builder.addCase(
      syncSaleshandyEmailAccountWithApiKeyRequest.pending,
      (state) => {
        state.syncSaleshandyEmailAccountWithApiKeyRequest.status =
          RequestStatus.Pending;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.message = null;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.error = null;
      },
    );
    builder.addCase(
      syncSaleshandyEmailAccountWithApiKeyRequest.fulfilled,
      (state, action) => {
        state.syncSaleshandyEmailAccountWithApiKeyRequest.status =
          RequestStatus.Succeeded;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.message =
          action.payload.message;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.error = null;
      },
    );
    builder.addCase(
      syncSaleshandyEmailAccountWithApiKeyRequest.rejected,
      (state, action) => {
        state.syncSaleshandyEmailAccountWithApiKeyRequest.status =
          RequestStatus.Failed;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.message =
          action.payload.message;
        state.syncSaleshandyEmailAccountWithApiKeyRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
    // Get Email Account Tags In Warmup Email Account Listing
    builder.addCase(getEmailAccountsTagsRequest.pending, (state) => {
      state.getEmailAccountsTagsRequest.status = RequestStatus.Pending;
      state.getEmailAccountsTagsRequest.error = null;
      state.getEmailAccountsTagsRequest.message = null;
    });
    builder.addCase(getEmailAccountsTagsRequest.fulfilled, (state, action) => {
      state.getEmailAccountsTagsRequest.status = RequestStatus.Succeeded;
      state.emailAccountTagsList = action.payload.payload;
    });
    builder.addCase(getEmailAccountsTagsRequest.rejected, (state, action) => {
      state.getEmailAccountsTagsRequest.status = RequestStatus.Failed;
      state.getEmailAccountsTagsRequest.message = action.payload.message;
      state.getEmailAccountsTagsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Assign Tags to Selected Email Accounts
    builder.addCase(emailAccountTagsAssignRequest.pending, (state) => {
      state.emailAccountTagsAssignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsAssignRequest.error = null;
      state.emailAccountTagsAssignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsAssignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsAssignRequest.status = RequestStatus.Succeeded;
        state.emailAccountTagsAssignRequest.message = action.payload.message;
      },
    );
    builder.addCase(emailAccountTagsAssignRequest.rejected, (state, action) => {
      state.emailAccountTagsAssignRequest.status = RequestStatus.Failed;
      state.emailAccountTagsAssignRequest.message = action.payload.message;
      state.emailAccountTagsAssignRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Assign Tags to Bulk Email Accounts
    builder.addCase(emailAccountTagsBulkAssignRequest.pending, (state) => {
      state.emailAccountTagsBulkAssignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsBulkAssignRequest.error = null;
      state.emailAccountTagsBulkAssignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsBulkAssignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsBulkAssignRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountTagsBulkAssignRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsBulkAssignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsBulkAssignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsBulkAssignRequest.message =
          action.payload.message;
        state.emailAccountTagsBulkAssignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Unassign Tags from Selected Email Accounts
    builder.addCase(emailAccountTagsUnassignRequest.pending, (state) => {
      state.emailAccountTagsUnassignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsUnassignRequest.error = null;
      state.emailAccountTagsUnassignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsUnassignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsUnassignRequest.status = RequestStatus.Succeeded;
        state.emailAccountTagsUnassignRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsUnassignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsUnassignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsUnassignRequest.message = action.payload.message;
        state.emailAccountTagsUnassignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Unassign Tags from Bulk Email Accounts
    builder.addCase(emailAccountTagsBulkUnassignRequest.pending, (state) => {
      state.emailAccountTagsBulkUnassignRequest.status = RequestStatus.Pending;
      state.emailAccountTagsBulkUnassignRequest.error = null;
      state.emailAccountTagsBulkUnassignRequest.message = null;
    });
    builder.addCase(
      emailAccountTagsBulkUnassignRequest.fulfilled,
      (state, action) => {
        state.emailAccountTagsBulkUnassignRequest.status =
          RequestStatus.Succeeded;
        state.emailAccountTagsBulkUnassignRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      emailAccountTagsBulkUnassignRequest.rejected,
      (state, action) => {
        state.emailAccountTagsBulkUnassignRequest.status = RequestStatus.Failed;
        state.emailAccountTagsBulkUnassignRequest.message =
          action.payload.message;
        state.emailAccountTagsBulkUnassignRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  resetEmailWarmupState,
  resetTurnOnOffEmailAccountWarmupState,
  setEmailAccountsFilters,
  setWarmupEmailAccountFilters,
  checkSingle,
  checkAll,
  selectAllContacts,
  clearSelection,
} = emailWarmupSlice.actions;

export default emailWarmupSlice.reducer;
