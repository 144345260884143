import React from 'react';

export const FireSetting = ({ width, height, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9174 15.4953C11.5582 14.8544 12.4274 14.4944 13.3337 14.4944C14.2401 14.4944 15.1093 14.8544 15.7501 15.4953C16.391 16.1362 16.751 17.0054 16.751 17.9117C16.751 18.818 16.391 19.6872 15.7501 20.3281C15.1093 20.969 14.2401 21.329 13.3337 21.329C12.4274 21.329 11.5582 20.969 10.9174 20.3281C10.2765 19.6872 9.9165 18.818 9.9165 17.9117C9.9165 17.0054 10.2765 16.1362 10.9174 15.4953ZM13.3337 16.4081C12.935 16.4081 12.5526 16.5665 12.2706 16.8485C11.9886 17.1305 11.8302 17.5129 11.8302 17.9117C11.8302 18.3105 11.9886 18.693 12.2706 18.9749C12.5526 19.2569 12.935 19.4153 13.3337 19.4153C13.7325 19.4153 14.1149 19.2569 14.3969 18.9749C14.6789 18.693 14.8373 18.3105 14.8373 17.9117C14.8373 17.5129 14.6789 17.1305 14.3969 16.8485C14.1149 16.5665 13.7325 16.4081 13.3337 16.4081Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3328 12.6489C13.8613 12.6489 14.2897 13.0773 14.2897 13.6058V15.4511C14.2897 15.9796 13.8613 16.408 13.3328 16.408C12.8044 16.408 12.376 15.9796 12.376 15.4511V13.6058C12.376 13.0773 12.8044 12.6489 13.3328 12.6489Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3328 19.4153C13.8613 19.4153 14.2897 19.8437 14.2897 20.3721V22.2175C14.2897 22.7459 13.8613 23.1743 13.3328 23.1743C12.8044 23.1743 12.376 22.7459 12.376 22.2175V20.3721C12.376 19.8437 12.8044 19.4153 13.3328 19.4153Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.892 15.2803C18.1562 15.738 17.9994 16.3232 17.5418 16.5874L15.9438 17.5101C15.4861 17.7743 14.9009 17.6175 14.6367 17.1599C14.3724 16.7022 14.5292 16.117 14.9869 15.8528L16.5849 14.9301C17.0425 14.6659 17.6277 14.8227 17.892 15.2803Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0325 18.6637C12.2966 19.1214 12.1396 19.7065 11.6819 19.9706L10.0826 20.8933C9.62491 21.1574 9.03975 21.0004 8.77567 20.5427C8.51158 20.0849 8.66857 19.4998 9.1263 19.2357L10.7256 18.313C11.1833 18.0489 11.7684 18.2059 12.0325 18.6637Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77567 15.2806C9.03975 14.8229 9.62491 14.6659 10.0826 14.93L11.6819 15.8526C12.1396 16.1167 12.2966 16.7019 12.0325 17.1596C11.7684 17.6174 11.1833 17.7743 10.7256 17.5102L9.1263 16.5876C8.66857 16.3235 8.51158 15.7383 8.77567 15.2806Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6365 18.6637C14.9006 18.2059 15.4857 18.0489 15.9435 18.313L17.5427 19.2357C18.0005 19.4998 18.1575 20.0849 17.8934 20.5427C17.6293 21.0004 17.0441 21.1574 16.5864 20.8933L14.9871 19.9706C14.5294 19.7065 14.3724 19.1214 14.6365 18.6637Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.22779 0.532862C7.5923 0.401528 7.99988 0.492581 8.27386 0.766554C8.99006 1.48275 9.90138 3.06744 10.3533 4.881C10.7422 6.44177 10.8275 8.32513 10.0237 10.0688C10.1481 10.0974 10.2325 10.0748 10.3068 10.0451C10.4604 9.98368 10.7089 9.82431 11.0598 9.43291C11.4073 9.04536 11.8143 8.47675 12.2957 7.668C12.4333 7.43698 12.658 7.27107 12.9193 7.20771C13.1806 7.14435 13.4564 7.18887 13.6845 7.33124C14.3212 7.72869 15.7215 8.88932 16.2647 10.519C16.4394 11.043 16.1562 11.6093 15.6322 11.784C15.1083 11.9586 14.542 11.6754 14.3673 11.1515C14.1761 10.5777 13.7814 10.0588 13.3959 9.6675C13.1056 10.0945 12.8246 10.4606 12.5489 10.7681C12.0856 11.2848 11.5946 11.6842 11.0489 11.9023C9.81669 12.3947 8.79589 11.8048 8.04704 11.1854C7.65033 10.8573 7.56758 10.2816 7.85581 9.85498C8.75593 8.5228 8.79343 6.89297 8.4126 5.36456C8.30695 4.94057 8.17298 4.54042 8.02587 4.17456C7.28355 6.40072 5.98744 7.79243 4.93017 8.87728C3.74932 10.0911 2.97852 12.0504 2.97852 13.7678C2.97852 15.2714 3.57199 16.7138 4.62921 17.7818C4.85269 17.9767 5.56718 18.5147 6.52138 18.961C7.02165 19.195 7.2375 19.7902 7.00351 20.2905C6.76951 20.7908 6.17428 21.0066 5.67401 20.7726C4.50931 20.2278 3.617 19.5609 3.27475 19.2538C3.26134 19.2418 3.24826 19.2294 3.23552 19.2167C1.79038 17.7715 0.978516 15.8115 0.978516 13.7678C0.978516 11.5513 1.93768 9.08484 3.4971 7.48217L3.49766 7.4816C4.82033 6.12443 6.275 4.52919 6.57114 1.38003C6.60742 0.994279 6.86327 0.664196 7.22779 0.532862Z"
      fill="currentColor"
    />
  </svg>
);

export default FireSetting;
