/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import Button from '../../../../shared/design-system/components/atoms/button';
import Tag from '../../../../shared/design-system/components/tag';
import Switch, {
  Size,
} from '../../../../shared/design-system/components/atoms/switch';
import { IProps } from './types';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster, { Theme } from '../../../../shared/toaster';
import DeleteEmailAccountModal from './components/delete-email-account-modal';
import {
  EmailAccountMethod,
  EmailAccountType,
} from '../../enums/email-account';
import { EmailAccount } from '../../types/email-account';
import AddEmailAccountModal from './components/add-email-account-modal/add-email-account-modal';
import {
  redirectWithoutRefresh,
  redirectWithRefresh,
} from '../../../../shared/utils';
import DisconnectEmailAccountModal from './components/disconnect-email-account-modal';
import EmptyDataIcon2 from '../../../../shared/components/images/empty-data-icon-2';
import TableSkeleton from '../../../../shared/design-system/components/atoms/skeletons';
import {
  SubscriptionPlans,
  SubscriptionPlanTitle,
} from '../../../../shared/utils/subscription-plans';
import { planError } from '../../../../shared/utils/errors/plan-permission-error/plan-error';
import ConfirmationModal from '../../../../shared/design-system/components/atoms/confirmation-modal';
import {
  getEmailAccountIdentifier,
  getEmailAccountTagClassName,
  getEmailAccountTagColor,
  getEmailAccountTagValue,
  getEmailAccountType,
  getOverlayTooltipMessage,
  getSendVsLimit,
  shouldShowAddEmailAccountModal,
  showHideLoading,
  showToasterWithErrorCheck,
  isSavedSMTPAccount,
} from './utils/helpers';
import Banner from '../../../../shared/design-system/components/atoms/banner';
import { BannerVariant } from '../../../../shared/design-system/components/atoms/banner/banner';
import { getAccountEmailSendingDetails } from '../../../../shared/utils/get-account-email-sending-detail';
import HeaderBanner from '../header-banner';
import ConnectSMTPIMAPAccountModal from './components/connect-smtp-imap-account-modal';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { UpdateEmailTabKeys } from './components/update-email-account/types';
import { constants } from '../../../../shared/enums/constants';
import NoSeatAvailableForEmailModal from './components/no-seat-available-for-email-modal';
import {
  getIsRequestPending,
  getEmailAccountId,
} from './components/update-email-account/helpers';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../../shared/utils/execute-on-request-status';
import SMTPLabel from './utils/smtp-label';
import ImageIcon from '../../../../shared/components/images/image-icon';
import { Images } from '../../../../shared/app-constants';
import SubscriptionStatus from '../billing-subscription/enums/subscription-status';
import ReactivateSubscriptionModal from '../billing-subscription/components/reactivate-subscription-modal/reactivate-subscription-modal';
import { contactAdmin } from '../../../../shared/utils/contact-admin';
import { Status } from '../../../../shared/enums/status';
import { ProfileProgressSteps } from '../../../../shared/types/user-setting';
import { OverlayTooltip } from '../../../../shared/design-system/components/overlay';
import { Placement } from '../../../../shared/design-system/components/overlay-taget';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import RestrictionErrorModal from '../../../../shared/components/restriction-error-modal';
import ErrorLogModal from './components/error-log-modal';
import { SMTPIMAP } from '../../enums/smtp-or-imap';
import { isValueBasedPlan } from '../billing-subscription/components/outreach-plans/utils/helper';

type IState = {
  showAddEmailAccountModal: boolean;
  showDisconnectEmailAccountModal: boolean;
  showDeleteEmailAccountModal: boolean;
  showEmailAccountQuotaChangeConfirmationModal: boolean;
  actionEmailAccount: EmailAccount;
  quotaChangeConfirmationMessage: string;
  showNoSeatAvailableForEmailModal: boolean;
  showConnectAndSaveSMTPIMAPModel: boolean;
  emailAccountId: number;
  showReactivateSubscriptionModal: boolean;
  restrictionErrorModalMeta: {
    show: boolean;
    planName: string;
  };
  errorLogAccountType: SMTPIMAP;
  isErrorLogModalVisible: boolean;
  errorLog: string;
};

class EmailAccounts extends React.Component<IProps, IState> {
  private emailAccountSettingPayload;

  constructor(props) {
    super(props);
    this.emailAccountSettingPayload = React.createRef();
    this.state = {
      showAddEmailAccountModal: false,
      showDisconnectEmailAccountModal: false,
      showDeleteEmailAccountModal: false,
      showEmailAccountQuotaChangeConfirmationModal: false,
      actionEmailAccount: null,
      quotaChangeConfirmationMessage: '',
      showNoSeatAvailableForEmailModal: false,
      showConnectAndSaveSMTPIMAPModel: false,
      emailAccountId: null,
      showReactivateSubscriptionModal: false,
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
      isErrorLogModalVisible: false,
      errorLogAccountType: SMTPIMAP.SMTP,
      errorLog: '',
    };

    this.makeDefaultEmailAccount = this.makeDefaultEmailAccount.bind(this);

    this.showAddEmailAccountModal = this.showAddEmailAccountModal.bind(this);
    this.hideAddEmailAccountModal = this.hideAddEmailAccountModal.bind(this);
    this.showDisconnectEmailAccountModal = this.showDisconnectEmailAccountModal.bind(
      this,
    );
    this.hideDisconnectEmailAccountModal = this.hideDisconnectEmailAccountModal.bind(
      this,
    );

    this.showDeleteEmailAccountModal = this.showDeleteEmailAccountModal.bind(
      this,
    );
    this.hideDeleteEmailAccountModal = this.hideDeleteEmailAccountModal.bind(
      this,
    );
    this.addEmailAccountHandler = this.addEmailAccountHandler.bind(this);
    this.onDisconnectEmailAccountSubmit = this.onDisconnectEmailAccountSubmit.bind(
      this,
    );
    this.onDeleteEmailAccountModalSubmit = this.onDeleteEmailAccountModalSubmit.bind(
      this,
    );

    this.onHideEmailAccountQuotaChangeConfirmationModal = this.onHideEmailAccountQuotaChangeConfirmationModal.bind(
      this,
    );

    this.onSubmitEmailAccountQuotaChangeConfirmationModal = this.onSubmitEmailAccountQuotaChangeConfirmationModal.bind(
      this,
    );
    this.getEmailHealthScoreColumn = this.getEmailHealthScoreColumn.bind(this);
    this.showAddEmailAccountModalHandler = this.showAddEmailAccountModalHandler.bind(
      this,
    );
    this.hideNoSeatAvailableForEmailModal = this.hideNoSeatAvailableForEmailModal.bind(
      this,
    );
    this.showConnectAndSaveSmtpImapAccountModel = this.showConnectAndSaveSmtpImapAccountModel.bind(
      this,
    );
    this.hideReactivateSubscriptionModal = this.hideReactivateSubscriptionModal.bind(
      this,
    );
    this.hideRestrictionErrorModal = this.hideRestrictionErrorModal.bind(this);
    this.upgradePlanWarning = this.upgradePlanWarning.bind(this);
  }

  async componentDidMount() {
    const {
      sendGetEmailAccountsRequest,
      showAddEmailAccountModalProp,
      sendGetSmtpImapAccountDetailsRequest,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const hashId = params.get('hashId');
    const emailAccountId = hashId && getEmailAccountId(hashId);
    const emailAccountType = params.get('emailAccountType');
    const showAddEmailAccountModal = params.get('showAddEmailAccountModal');
    const showAddSmtpImapAccountModal = params.get('connect-smtp');

    sendGetEmailAccountsRequest();
    if (showAddEmailAccountModalProp || showAddEmailAccountModal) {
      this.showAddEmailAccountModalHandler();
    }
    if (emailAccountType && emailAccountId) {
      sendGetSmtpImapAccountDetailsRequest(+emailAccountId);
    }
    if (showAddSmtpImapAccountModal) {
      this.showConnectAndSaveSmtpImapAccountModel();
    }

    this.setState({ emailAccountId: null });
  }

  async componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      connectEmailAccountRequestStatus,
      connectEmailAccountRequestError,
      disconnectEmailAccountRequestStatus,
      disconnectEmailAccountRequestMessage,
      disconnectEmailAccountRequestError,
      setDefaultEmailAccountRequestStatus,
      setDefaultEmailAccountRequestMessage,
      setDefaultEmailAccountRequestError,
      deleteEmailAccountRequestStatus,
      deleteEmailAccountRequestMessage,
      deleteEmailAccountRequestError,
      updateEmailAccountRequestStatus,
      updateEmailAccountRequestMessage,
      updateEmailAccountRequestError,
      authUrl,
      isLoading,
      sendGetEmailAccountsRequest,
      showAddEmailAccountModalProp,
      getConnectedUsersAndEmailAccountsRequestStatus,
      getConnectedUsersAndEmailAccountsRequestError,
      resetConnectedUsersAndEmailAccountsRequest,
      getConnectSmtpImapRequestStatus,
      getConnectSmtpImapRequestMessage,
      sendConnectSmtpImapResponse,
      sendGetSmtpImapAccountDetailsRequestStatus,
      sendUpdateSmtpImapAccountRequestStatus,
      sendUpdateEmailAccountRequestMessage,
      resetUpdateSmtpImapAccountDetails,
      sendGetReactivateSubscriptionRequestStatus,
      resetReactivateSubscriptionResponse,
      sendGetReactivateSubscriptionResponse,
      sendGetReactivateSubscriptionRequestError,
      sendGetUserSettingsRequest,
      sendUpdateSmtpImapAccountDetailsResponse,
      updateProfileProgressRequestStatus,
      t,
    } = this.props;

    showHideLoading(isLoading, prevProps.isLoading);

    executeOnRequestStatusWithPrevStatusCheck({
      status: connectEmailAccountRequestStatus,
      prevStatus: prevProps.connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: disconnectEmailAccountRequestStatus,
      prevStatus: prevProps.disconnectEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(disconnectEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.hideDisconnectEmailAccountModal();
        sendGetEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(disconnectEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: setDefaultEmailAccountRequestStatus,
      prevStatus: prevProps.setDefaultEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(setDefaultEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        sendGetEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(setDefaultEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: deleteEmailAccountRequestStatus,
      prevStatus: prevProps.deleteEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(deleteEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.hideDeleteEmailAccountModal();
        sendGetEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(deleteEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: updateEmailAccountRequestStatus,
      prevStatus: prevProps.updateEmailAccountRequestStatus,
      onSuccess: () => {
        toaster.success(updateEmailAccountRequestMessage, {
          theme: Theme.New,
        });
        this.onHideEmailAccountQuotaChangeConfirmationModal();
        sendGetEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(updateEmailAccountRequestError);
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      prevStatus: prevProps.getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        this.showAddEmailAccountModal();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        showToasterWithErrorCheck(
          getConnectedUsersAndEmailAccountsRequestError,
        );
      },
    });

    // Connect SMTP IMAP Account
    executeOnRequestStatusWithPrevStatusCheck({
      status: getConnectSmtpImapRequestStatus,
      prevStatus: prevProps.getConnectSmtpImapRequestStatus,
      onSuccess: () => {
        if (
          sendConnectSmtpImapResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendConnectSmtpImapResponse?.imapConnection?.isConnectionEstablished
        ) {
          this.hideConnectAndSaveSmtpImapAccountModel();
          toaster.success(getConnectSmtpImapRequestMessage, {
            theme: Theme.New,
          });
          sendGetEmailAccountsRequest();
          this.updateConnectEmailProfileProgressStep();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(getConnectSmtpImapRequestMessage);
      },
    });

    // Get User Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: updateProfileProgressRequestStatus,
      prevStatus: prevProps.updateProfileProgressRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });

    // Get SMTP IMAP  Account Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendGetSmtpImapAccountDetailsRequestStatus,
      prevStatus: prevProps.sendGetSmtpImapAccountDetailsRequestStatus,
      onSuccess: () => {
        this.showConnectAndSaveSmtpImapAccountModel();
      },
      onFailed: () => {
        showToasterWithErrorCheck(t('messages.email_account_not_found'));
      },
    });

    // Update SMTP IMAP  Account Details
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendUpdateSmtpImapAccountRequestStatus,
      prevStatus: prevProps.sendUpdateSmtpImapAccountRequestStatus,
      onSuccess: () => {
        if (
          sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection
            ?.isConnectionEstablished &&
          sendUpdateSmtpImapAccountDetailsResponse?.imapConnection
            ?.isConnectionEstablished
        ) {
          toaster.success(sendUpdateEmailAccountRequestMessage, {
            theme: Theme.New,
          });
          this.hideConnectAndSaveSmtpImapAccountModel();
          sendGetEmailAccountsRequest();
          resetUpdateSmtpImapAccountDetails();
        }
      },
      onFailed: () => {
        showToasterWithErrorCheck(sendUpdateEmailAccountRequestMessage);
      },
    });
    executeOnRequestStatusWithPrevStatusCheck({
      status: sendGetReactivateSubscriptionRequestStatus,
      prevStatus: prevProps.sendGetReactivateSubscriptionRequestStatus,
      onSuccess: () => {
        this.hideReactivateSubscriptionModal();
        sendGetUserSettingsRequest();
        resetReactivateSubscriptionResponse();
        toaster.success(sendGetReactivateSubscriptionResponse?.message);
      },
      onFailed: () => {
        this.hideReactivateSubscriptionModal();
        showToasterWithErrorCheck(
          sendGetReactivateSubscriptionRequestError?.message,
        );
      },
    });

    if (
      shouldShowAddEmailAccountModal(
        showAddEmailAccountModalProp,
        prevProps.showAddEmailAccountModalProp,
      )
    ) {
      this.showAddEmailAccountModalHandler();
    }
  }

  onHideEmailAccountQuotaChangeConfirmationModal() {
    this.setState({
      showEmailAccountQuotaChangeConfirmationModal: false,
      quotaChangeConfirmationMessage: '',
    });
    this.emailAccountSettingPayload.current = null;
  }

  onSubmitEmailAccountQuotaChangeConfirmationModal() {
    const { sendUpdateEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;

    sendUpdateEmailAccountRequest(
      actionEmailAccount.id,
      this.emailAccountSettingPayload.current,
    );
  }

  onDeleteEmailAccountModalSubmit() {
    const { sendDeleteEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;
    sendDeleteEmailAccountRequest(actionEmailAccount.id);
  }

  onDisconnectEmailAccountSubmit() {
    const { sendDisconnectEmailAccountRequest } = this.props;
    const { actionEmailAccount } = this.state;
    sendDisconnectEmailAccountRequest(actionEmailAccount.id);
  }

  updateConnectEmailProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const connectEmailStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.ConnectEmail,
      );
      if (!connectEmailStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.ConnectEmail,
          isCompleted: true,
        });
      }
    }
  };

  getEmailHealthScoreColumn = (emailAccount, t) =>
    emailAccount.healthScore ? (
      <div className="regular-2">
        {emailAccount.healthScore}/{constants.EMAIL_ACCOUNT_HEALTH_SCORE_LIMIT}
      </div>
    ) : (
      <span
        className="regular-2"
        {...accessibleOnClick(() =>
          this.goToEditEmailAccountPage(
            emailAccount.id,
            UpdateEmailTabKeys.EMAIL_HEALTH_SCORE,
            emailAccount.type,
            emailAccount,
          ),
        )}
      >
        {t(`labels.check_health_score`)}
      </span>
    );

  getSwitchInputCell = (emailAccount) =>
    emailAccount.status ? (
      <Switch
        checked={true}
        onChange={() => this.showDisconnectEmailAccountModal(emailAccount)}
        size={Size.Small}
        className="connected-status"
        tooltip="Disconnect"
        placement={Placement.Bottom}
      />
    ) : (
      <Switch
        checked={false}
        onChange={() => this.onConnectSwitchHandler(emailAccount)}
        size={Size.Small}
        className="disconnected-status"
        tooltip="Connect"
        placement={Placement.Bottom}
      />
    );

  reactivateSubscription = () => {
    const { sendReactivateSubscriptionRequest, customerId } = this.props;
    const { t, firstName } = this.props;

    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.reactivate_subscription_email_body',
    )}`;
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      sendReactivateSubscriptionRequest({ customerId });
    } else {
      contactAdmin(t('messages.reactivate_subscription'), emailBody);
    }
  };

  showConnectAndSaveSmtpImapAccountModel = () => {
    const { subscriptionPlan } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(5000);
      } else {
        planError(5001);
      }
    } else {
      this.setState({
        showConnectAndSaveSMTPIMAPModel: true,
        showAddEmailAccountModal: false,
      });
    }
  };

  hideConnectAndSaveSmtpImapAccountModel = () => {
    const {
      sendConnectSmtpImapResponse,
      getConnectSmtpImapRequestMessage,
      sendGetEmailAccountsRequest,
      resetSmtpImapTestConnection,
      resetSmtpIMapConnectResponse,
      resetUpdateSmtpImapAccountDetails,
      sendUpdateSmtpImapAccountDetailsResponse,
      sendUpdateEmailAccountRequestMessage,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const showAddSmtpImapAccountModal = params.get('connect-smtp');

    this.setState({
      showConnectAndSaveSMTPIMAPModel: false,
    });

    if (
      !!sendConnectSmtpImapResponse?.smtpConnection?.error ||
      !!sendConnectSmtpImapResponse?.imapConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.smtpConnection?.error ||
      !!sendUpdateSmtpImapAccountDetailsResponse?.imapConnection?.error
    ) {
      const message =
        getConnectSmtpImapRequestMessage ||
        sendUpdateEmailAccountRequestMessage;
      toaster.success(message, { theme: Theme.New });
      sendGetEmailAccountsRequest();
    }
    resetSmtpImapTestConnection();
    resetSmtpIMapConnectResponse();
    resetUpdateSmtpImapAccountDetails();

    if (showAddSmtpImapAccountModal) {
      this.removeParamsFromUrl();
    }
  };

  onConnectSwitchHandler = (emailAccount) => {
    if (hasPermission('EMAIL.WRITE')) {
      const { sendGetSmtpImapAccountDetailsRequest } = this.props;

      if (isSavedSMTPAccount(emailAccount) || emailAccount.type === 3) {
        sendGetSmtpImapAccountDetailsRequest(emailAccount.id);
        this.setState({
          emailAccountId: emailAccount.id,
        });
      } else {
        this.addEmailAccountHandler(
          getEmailAccountType(emailAccount),
          emailAccount.id,
        );
      }
    }
  };

  redirectToGeneralSettings = (emailAccount) => {
    const isConnected = emailAccount?.status === Status.Active;
    const type = !isConnected ? emailAccount?.type : null;

    this.goToEditEmailAccountPage(
      emailAccount.id,
      UpdateEmailTabKeys.GENERAL_SETTINGS,
      emailAccount,
      type,
    );
  };

  redirectToSendingSettings = (emailAccount) => {
    const isConnected = emailAccount?.status === Status.Active;
    const type = !isConnected ? emailAccount?.type : null;

    this.goToEditEmailAccountPage(
      emailAccount.id,
      UpdateEmailTabKeys.SENDING_SETTINGS,
      emailAccount,
      type,
    );
  };

  redirectToEmailHealthScore = (emailAccount) => {
    const isConnected = emailAccount?.status === Status.Active;
    const type = !isConnected ? emailAccount?.type : null;

    this.goToEditEmailAccountPage(
      emailAccount.id,
      UpdateEmailTabKeys.EMAIL_HEALTH_SCORE,
      emailAccount,
      type,
    );
  };

  emailAccountTagOnClick = (emailAccount) =>
    !emailAccount.isDefault
      ? () => this.makeDefaultEmailAccount(emailAccount)
      : undefined;

  removeParamsFromUrl = () => {
    redirectWithoutRefresh('/settings/email-accounts');
  };

  upgradePlanWarning = () => {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      planError(2005);
    } else {
      planError(3005);
    }
  };

  showErrorLogModal(accountType: SMTPIMAP, errorLog: string) {
    this.setState({
      isErrorLogModalVisible: true,
      errorLogAccountType: accountType,
      errorLog,
    });
  }

  hideErrorLogModal() {
    this.setState({ isErrorLogModalVisible: false });
  }

  showDisconnectEmailAccountModal(actionEmailAccount: EmailAccount) {
    this.setState({
      showDisconnectEmailAccountModal: true,
      actionEmailAccount,
    });
  }

  hideDisconnectEmailAccountModal() {
    this.setState({
      showDisconnectEmailAccountModal: false,
      actionEmailAccount: null,
    });
  }

  hideNoSeatAvailableForEmailModal() {
    this.setState({
      showNoSeatAvailableForEmailModal: false,
    });
  }

  hideDeleteEmailAccountModal() {
    this.setState({
      showDeleteEmailAccountModal: false,
      actionEmailAccount: null,
    });
  }

  showDeleteEmailAccountModal(actionEmailAccount: EmailAccount) {
    this.setState({
      showDeleteEmailAccountModal: true,
      actionEmailAccount,
    });
  }

  hideAddEmailAccountModal() {
    const {
      showAddEmailAccountModalProp,
      handleAddEmailAccountModal,
    } = this.props;

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const showAddEmailAccountModal = params.get('showAddEmailAccountModal');

    if (showAddEmailAccountModalProp) {
      handleAddEmailAccountModal(false);
    }
    this.setState({ showAddEmailAccountModal: false });

    if (showAddEmailAccountModal) {
      this.removeParamsFromUrl();
    }
  }

  showAddEmailAccountModalHandler() {
    if (hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT)) {
      const {
        sendGetConnectedUsersAndEmailAccountsRequest,
        subscriptionPlan,
      } = this.props;
      if (subscriptionPlan === SubscriptionPlans.TRIAL) {
        this.showAddEmailAccountModal();
      } else {
        hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
          sendGetConnectedUsersAndEmailAccountsRequest();
      }
    }
  }

  hideReactivateSubscriptionModal() {
    this.setState({
      showReactivateSubscriptionModal: false,
    });
  }

  makeDefaultEmailAccount(actionEmailAccount: EmailAccount) {
    const { sendSetDefaultEmailAccountRequest } = this.props;
    sendSetDefaultEmailAccountRequest(actionEmailAccount.id);
  }

  addEmailAccountHandler(method: EmailAccountMethod, emailAccountId?: number) {
    const { subscriptionPlan, sendConnectEmailAccountRequest } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(5000);
      } else {
        planError(5001);
      }
    } else {
      hasResource(ResourceIdentifiers.EMAIL_ACCOUNT_CONNECT) &&
        sendConnectEmailAccountRequest(method, emailAccountId);
    }
  }

  hideRestrictionErrorModal() {
    this.setState({
      restrictionErrorModalMeta: {
        show: false,
        planName: '',
      },
    });
  }

  showAddEmailAccountModal() {
    const {
      subscriptionPlan,
      planType,
      totalEmailAccounts,
      slots,
      subscriptionPlanStatus,
    } = this.props;

    if (subscriptionPlan === SubscriptionPlans.FREE) {
      this.upgradePlanWarning();
    } else if (
      subscriptionPlan !== SubscriptionPlans.TRIAL &&
      Number(totalEmailAccounts) >= slots &&
      !isValueBasedPlan(planType)
    ) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (subscriptionPlanStatus === SubscriptionStatus.Canceled) {
          this.setState({ showReactivateSubscriptionModal: true });
          return;
        }
        if (subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME) {
          this.setState({
            restrictionErrorModalMeta: {
              show: true,
              planName: SubscriptionPlanTitle.EmailOutreach,
            },
          });
          return;
        }
        if (
          subscriptionPlan === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME
        ) {
          this.setState({
            restrictionErrorModalMeta: {
              show: true,
              planName: SubscriptionPlanTitle.EmailOutreachPro,
            },
          });
          return;
        }
      }
      this.setState({ showNoSeatAvailableForEmailModal: true });
    } else {
      this.setState({ showAddEmailAccountModal: true });
    }
  }

  goToEditEmailAccountPage(
    id: number,
    tab: UpdateEmailTabKeys,
    emailAccount: EmailAccount,
    type: number = null,
  ) {
    const { history, sendGetSmtpImapAccountDetailsRequest } = this.props;

    if (
      type === EmailAccountType.SmtpImap &&
      emailAccount.status !== Status.Active
    ) {
      sendGetSmtpImapAccountDetailsRequest(id);
    } else {
      history.push({
        pathname: `/settings/email-accounts/${emailAccount.hashId}/${tab}`,
        state: {
          emailAccountId: id,
        },
      });
    }
  }

  bannerMessageForTrailPlan() {
    const { featureUsages } = this.props;
    const shAccountEmailSendingDetails = getAccountEmailSendingDetails(
      featureUsages,
    );

    return `During trial period, you can send ${
      shAccountEmailSendingDetails.quota
    } emails and your remaining quota is ${
      +shAccountEmailSendingDetails.remainingQuota >= 0
        ? shAccountEmailSendingDetails.remainingQuota
        : '0'
    }/${shAccountEmailSendingDetails.quota}.${
      !hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
        ? ' Contact admin to upgrade'
        : ''
    }`;
  }

  render() {
    const {
      showAddEmailAccountModal,
      showDisconnectEmailAccountModal,
      showDeleteEmailAccountModal,
      showEmailAccountQuotaChangeConfirmationModal,
      quotaChangeConfirmationMessage,
      showNoSeatAvailableForEmailModal,
      showConnectAndSaveSMTPIMAPModel,
      emailAccountId,
      showReactivateSubscriptionModal,
      restrictionErrorModalMeta,
      isErrorLogModalVisible,
      errorLogAccountType,
      errorLog,
    } = this.state;
    const {
      getEmailAccountsRequestStatus,
      connectEmailAccountRequestStatus,
      updateEmailAccountRequestStatus,
      disconnectEmailAccountRequestStatus,
      deleteEmailAccountRequestStatus,
      emailAccounts,
      isEmpty,
      subscriptionPlan,
      t,
      firstName,
      getConnectedUsersAndEmailAccountsRequestStatus,
      getTestSMTPConnectionRequestStatus,
      getTestIMAPConnectionRequestStatus,
      getConnectSmtpImapRequestStatus,
      sendGetSmtpImapAccountDetailsRequest,
      sendUpdateSmtpImapAccountRequestStatus,
      subscriptionEndDate,
      sendGetReactivateSubscriptionRequestStatus,
      agencyConfig,
    } = this.props;

    const isDeleteEmailAccountRequestPending =
      deleteEmailAccountRequestStatus === RequestStatus.Pending;

    const isUpdateEmailAccountRequestPending =
      updateEmailAccountRequestStatus === RequestStatus.Pending;

    const isDisconnectEmailAccountRequestPending =
      disconnectEmailAccountRequestStatus === RequestStatus.Pending;

    const isConnectEmailAccountRequestPending =
      connectEmailAccountRequestStatus === RequestStatus.Pending;

    const Dlttooltip = (
      <Tooltip id="popover-basic" className="bs-tooltip-inner">
        Delete
      </Tooltip>
    );
    const EditTooltip = (
      <Tooltip id="popover-basic" className="bs-tooltip-inner">
        Edit
      </Tooltip>
    );

    const CustomTooltip = (content) => (
      <Tooltip id="popover-basic" className="bs-tooltip-inner">
        {content}
      </Tooltip>
    );

    const emailAccountClasses = classNames([
      'bs-table-content',
      {
        'bs-table-content-free-plan':
          subscriptionPlan === SubscriptionPlans.TRIAL,
      },
      {
        'bs-table-content-default':
          subscriptionPlan !== SubscriptionPlans.TRIAL,
      },
    ]);

    return (
      <>
        <div className="email-account-container h-100">
          {subscriptionPlan === SubscriptionPlans.TRIAL && (
            <Banner
              pageHeaderBannerMessage={this.bannerMessageForTrailPlan()}
              ctaText={
                hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
                t('labels.upgrade_now')
              }
              ctaURL={
                hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) &&
                '/settings/billing/upgrade-plan'
              }
              variant={BannerVariant.Warning}
            />
          )}
          <div className="email-account-header">
            <div className="d-flex align-items-center">
              <span className="semibold-3">Email Accounts</span>
            </div>
            <div>
              {/* TODO: refactor subscription plan check condition */}
              {hasPermission(Permissions.EMAIL_WRITE) && (
                <Button
                  variant={Button.Variant.Primary}
                  onClick={this.showAddEmailAccountModalHandler}
                  disabled={subscriptionPlan === SubscriptionPlans.FREE}
                  className="header-btn"
                  isLoading={getIsRequestPending(
                    getConnectedUsersAndEmailAccountsRequestStatus,
                  )}
                >
                  Add Email Account
                </Button>
              )}
            </div>
          </div>
          {subscriptionPlan === SubscriptionPlans.FREE && (
            <HeaderBanner isEmailAccountPage={true} />
          )}
          {isEmpty &&
            getEmailAccountsRequestStatus === RequestStatus.Pending && (
              <TableSkeleton colCount={5} />
            )}
          {isEmpty && getEmailAccountsRequestStatus !== RequestStatus.Pending && (
            <div className="empty-block">
              <EmptyDataIcon2 />
              <p>No Email Account Found</p>
            </div>
          )}
          {!isEmpty && getEmailAccountsRequestStatus !== RequestStatus.Pending && (
            <div className="email-account-list h-100">
              <div className="bs-table bs-table-small h-100">
                <div className="bs-table-container h-100">
                  <div className={emailAccountClasses}>
                    <table>
                      <thead className="bs-table-thead email-table-header-background">
                        <tr>
                          <th className="text-left"> </th>
                          <th>
                            <span className="table-heading">
                              {t(`labels.sender`)}
                            </span>
                          </th>
                          <th className="column-visibility w-50  table-heading">
                            {t(`labels.sending_limit`)}
                          </th>
                          <th className="column-visibility w-50 table-heading">
                            {t(`labels.email_score`)}
                          </th>
                          <th className="w-50 table-heading">
                            {t(`labels.connected_by`)}
                          </th>
                          <th className="text-right table-heading">
                            {t(`labels.actions`)}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bs-table-tbody">
                        {emailAccounts.map((emailAccount) => (
                          <tr
                            className="bs-table-row bs-table-row-level-0"
                            key={emailAccount.id}
                          >
                            <td>
                              <div className="table-cell ml-2">
                                {this.getSwitchInputCell(emailAccount)}
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className="sender-data-row">
                                  <p className="gray-txt-9 ellipse-sender-text">
                                    <OverlayTrigger
                                      placement={Placement.Bottom}
                                      overlay={CustomTooltip(
                                        getOverlayTooltipMessage(emailAccount),
                                      )}
                                    >
                                      <span
                                        {...accessibleOnClick(() =>
                                          this.redirectToGeneralSettings(
                                            emailAccount,
                                          ),
                                        )}
                                        className="regular-2 popover-arrow-color-txt"
                                      >
                                        {getOverlayTooltipMessage(emailAccount)}
                                      </span>
                                    </OverlayTrigger>
                                  </p>
                                  {emailAccount?.smtpImapConnectionPayload ? (
                                    <div className="smtp-label-wrap ml-2">
                                      <SMTPLabel />
                                    </div>
                                  ) : (
                                    <ImageIcon
                                      src={getEmailAccountIdentifier(
                                        emailAccount,
                                      )}
                                      className="mr-1 ml-2"
                                    />
                                  )}
                                  <Tag
                                    value={getEmailAccountTagValue(
                                      emailAccount,
                                    )}
                                    color={getEmailAccountTagColor(
                                      emailAccount,
                                    )}
                                    className={getEmailAccountTagClassName(
                                      emailAccount,
                                    )}
                                    onClick={this.emailAccountTagOnClick(
                                      emailAccount,
                                    )}
                                  />
                                </div>
                                <div className="d-flex">
                                  <div
                                    className="sender-rmail-row"
                                    {...accessibleOnClick(() =>
                                      this.redirectToGeneralSettings(
                                        emailAccount,
                                      ),
                                    )}
                                  >
                                    <span className="regular-1">
                                      {emailAccount.fromEmail}
                                    </span>
                                  </div>
                                  {isSavedSMTPAccount(emailAccount) && (
                                    <div
                                      role="button"
                                      {...accessibleOnClick(() => {
                                        sendGetSmtpImapAccountDetailsRequest(
                                          emailAccount.id,
                                        );
                                        this.setState({
                                          emailAccountId: emailAccount.id,
                                        });
                                      })}
                                    >
                                      <ImageIcon
                                        src={Images.AlertTriangleRed}
                                        className="ml-2"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="custom-domain-mediaquery">
                                <strong className="semibold-1">Send/day</strong>
                                <span className="regular-1">
                                  {getSendVsLimit(emailAccount.settings)}
                                </span>
                                <small>|</small>
                                <strong className="semibold-1">
                                  {t(`labels.email_score`)}
                                </strong>
                                <div className="email-score">
                                  <span className="semibold-1">
                                    {this.getEmailHealthScoreColumn(
                                      emailAccount,
                                      t,
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="column-visibility">
                              <OverlayTooltip
                                text={t(
                                  'messages.email_account_sending_limit_tooltip',
                                )}
                              >
                                <span
                                  className=" text-decoration-underline-hover blue-txt-11 pointer"
                                  {...accessibleOnClick(() => {
                                    this.redirectToSendingSettings(
                                      emailAccount,
                                    );
                                  })}
                                >
                                  {getSendVsLimit(emailAccount.settings)}
                                </span>
                              </OverlayTooltip>
                            </td>
                            <td className="column-visibility w-50">
                              <div
                                className={`email-score ${
                                  emailAccount?.type ===
                                    EmailAccountType.SmtpImap &&
                                  'cursor-not-allowed'
                                }`}
                              >
                                <OverlayTooltip
                                  text={t(
                                    'messages.email_account_health_score_tooltip',
                                  )}
                                >
                                  <span
                                    {...accessibleOnClick(() => {
                                      this.redirectToEmailHealthScore(
                                        emailAccount,
                                      );
                                    })}
                                  >
                                    {this.getEmailHealthScoreColumn(
                                      emailAccount,
                                      t,
                                    )}
                                  </span>
                                </OverlayTooltip>
                              </div>
                            </td>
                            <td className="px-2 w-100">
                              <div className="d-flex">
                                {/* TODO - Create seperate atom for avatar */}
                                <div className="mr-2">
                                  <span className="bs-avatar bs-avatar-md bs-avatar-circle light-blue-color">
                                    <span className="bs-avatar-string text-primary">
                                      {emailAccount.user.firstName[0]?.toUpperCase()}
                                      {emailAccount.user.lastName[0]?.toUpperCase()}
                                    </span>
                                  </span>
                                </div>
                                <div className="ellipse-email-text">
                                  <OverlayTrigger
                                    placement={Placement.Bottom}
                                    overlay={CustomTooltip(
                                      `${emailAccount.user.firstName} ${emailAccount.user.lastName}`,
                                    )}
                                  >
                                    <span className="pointer">
                                      {emailAccount.user.firstName}{' '}
                                      {emailAccount.user.lastName}
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </td>
                            <td className="text-right">
                              <div className="grid-action d-flex">
                                <div
                                  {...accessibleOnClick(() => {
                                    this.redirectToGeneralSettings(
                                      emailAccount,
                                    );
                                  })}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={EditTooltip}
                                  >
                                    <ImageIcon src={Images.Pencil} />
                                  </OverlayTrigger>
                                </div>
                                <div
                                  className="ml-2"
                                  {...accessibleOnClick(() => {
                                    this.showDeleteEmailAccountModal(
                                      emailAccount,
                                    );
                                  })}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={Dlttooltip}
                                  >
                                    <ImageIcon src={Images.TrashLight} />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AddEmailAccountModal
          show={showAddEmailAccountModal}
          onClose={this.hideAddEmailAccountModal}
          isRequestPending={isConnectEmailAccountRequestPending}
          t={t}
          onGoogleAccountSelect={() => null}
          onMicrosoftAccountSelect={() => null}
          onSMTPAccountSelect={this.showConnectAndSaveSmtpImapAccountModel}
          onSaleshandyAccountSelect={() => {}}
        />

        <DisconnectEmailAccountModal
          show={showDisconnectEmailAccountModal}
          onSubmit={this.onDisconnectEmailAccountSubmit}
          onClose={this.hideDisconnectEmailAccountModal}
          isRequestPending={isDisconnectEmailAccountRequestPending}
        />
        <DeleteEmailAccountModal
          show={showDeleteEmailAccountModal}
          onSubmit={this.onDeleteEmailAccountModalSubmit}
          onClose={this.hideDeleteEmailAccountModal}
          isRequestPending={isDeleteEmailAccountRequestPending}
        />
        <ConfirmationModal
          show={showEmailAccountQuotaChangeConfirmationModal}
          onClose={this.onHideEmailAccountQuotaChangeConfirmationModal}
          onSubmit={this.onSubmitEmailAccountQuotaChangeConfirmationModal}
          title={t('messages.make_changes_to_daily_sending_limit')}
          contents={[quotaChangeConfirmationMessage]}
          showSuccessButton={true}
          showCancelButton={true}
          successButtonText={t('labels.okay')}
          cancelButtonText={t('labels.cancel')}
          showFooterBorder={true}
          isRequestPending={isUpdateEmailAccountRequestPending}
          iconType="warning"
          showCloseButton={true}
        />
        <ConnectSMTPIMAPAccountModal
          show={showConnectAndSaveSMTPIMAPModel}
          onClose={this.hideConnectAndSaveSmtpImapAccountModel}
          isSMTPTestConnectionRequestPending={
            getTestSMTPConnectionRequestStatus === RequestStatus.Pending
          }
          isIMAPTestConnectionRequestPending={
            getTestIMAPConnectionRequestStatus === RequestStatus.Pending
          }
          isConnectAccountRequestPending={
            getConnectSmtpImapRequestStatus === RequestStatus.Pending
          }
          isUpdateSmtpImapAccountRequestPending={
            sendUpdateSmtpImapAccountRequestStatus === RequestStatus.Pending
          }
          emailAccountId={emailAccountId}
          agencyConfig={agencyConfig}
          showErrorLogModal={(accountType, errorLog) =>
            this.showErrorLogModal(accountType, errorLog)
          }
        />
        <ErrorLogModal
          show={isErrorLogModalVisible}
          onClose={() => this.hideErrorLogModal()}
          accountType={errorLogAccountType}
          errorLog={errorLog}
        />
        <NoSeatAvailableForEmailModal
          show={showNoSeatAvailableForEmailModal}
          onClose={this.hideNoSeatAvailableForEmailModal}
          firstName={firstName}
        />
        <ReactivateSubscriptionModal
          show={showReactivateSubscriptionModal}
          onClose={this.hideReactivateSubscriptionModal}
          onReactivateSubscription={this.reactivateSubscription}
          subscriptionEndDate={subscriptionEndDate}
          isRequestPending={
            sendGetReactivateSubscriptionRequestStatus === RequestStatus.Pending
          }
          reactivateSubscriptionModalMessage1="not_allowed_to_add_email_accounts_message_1"
          reactivateSubscriptionModalMessage2="not_allowed_to_add_email_accounts_message_2"
          reactivateSubscriptionModalHeaderMessage="no_seat_available_email_modal_title"
          submitButtonText={
            hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
              ? 'reactivate_subscription'
              : 'contact_admin'
          }
        />
        <RestrictionErrorModal
          show={restrictionErrorModalMeta.show}
          onClose={this.hideRestrictionErrorModal}
          planName={restrictionErrorModalMeta.planName}
          restrictionOn="email slots"
          feature="email accounts"
          firstName={firstName}
        />
      </>
    );
  }
}

export default EmailAccounts;
