const onboarding = {
  step3: {
    hi: `Salut`,
    welcome_to_saleshandy: `Bienvenue dans Saleshandy v3.0 ! Votre nouveau lieu d'engagement commercial.`,
    all_of: `Toutes vos communications par e-mail peuvent être gérées à l'aide de séquences -
    campagnes goutte à goutte multi-touch pour automatiser votre carnet de ventes et booster
    productivité.`,
    lets_quickly: `Voyons rapidement comment l'utiliser.`,
    lets_start: `Let's Start`,
  },
  step4: {
    set_up: `Mettre en place le flux de communication. Si vous n'avez pas de campagne particulière en tête pour le moment, vous pouvez explorer notre exemple de séquence.`,
  },
  step5: {
    this_is_where: `C'est ici que vous pouvez analyser les perspectives et suivre l'engagement de votre séquence.`,
    we_will_come: `Nous y reviendrons en détail après avoir créé les étapes et lancé la séquence.`,
    great: `🎉 Super!`,
  },
  step6: {
    start_by_prospects: `Commencez par ajouter des prospects avec lesquels vous souhaitez commencer à interagir avec des e-mails.`,
  },
  step7: {
    steps_created: `Étapes créées`,
    prospects_added: `Perspectives ajoutées`,
    activate_the_sequence: `Activez la séquence pour envoyer les e-mails par étapes à vos prospects.
    Vous pouvez ensuite vérifier les performances de la séquence dans`,
    tabs: `onglets`,
    you_are_all_set: `Vous êtes prêt!`,
  },
  step8: {
    way_to_go: `Marche à suivre!!`,
    for_every_sequence: `Pour chaque séquence, vous pouvez surveiller les performances de la séquence dans`,
    check_our_tutorials: `Consultez nos tutoriels pour apprendre les meilleures pratiques pour utiliser Saleshandy pour booster
    votre entreprise.`,
  },
};

const emptyList = {
  emailsTab: {
    you_asked: `Vous avez demandé et nous avons écouté. C'est officiel.`,
    email_tab_another_way: `L'onglet E-mails, un autre moyen de suivre efficacement vos e-mails,
    sort le 5 octobre 2021.`,
    through_this: `Grâce à cela, vous pouvez désormais facilement gérer et surveiller votre courrier électronique
    Activités. Avec l'onglet e-mail, vous pourrez voir comment
    de nombreux mails sont envoyés ainsi que le planning des mails à venir.`,
    please_click_on: `Veuillez cliquer sur`,
    link_to_view: `lien pour afficher notre feuille de route de produits et nous fournir tout
    commentaires supplémentaires que vous avez pour nous aider à façonner notre
    produit meilleur pour nos utilisateurs`,
  },
  templates: {
    start_templates: 'Commencez avec des modèles',
    templates_help:
      'Les modèles vous aideront à gérer votre contenu afin que vous puissiez créer vos séquences plus rapidement que jamais',
    add_templates: 'Nouveau modèle',
  },
};

const templates = {
  clone_warning: 'Êtes-vous sûr de vouloir cloner le modèle',
  clone_template: 'Modèle de clone',
  delete_warning:
    'Voulez-vous vraiment supprimer ce modèle ? Le modèle sera supprimé définitivement et vous ne pourrez pas annuler cette action',
  delete_template: 'Supprimer le modèle',
  share_warning:
    "Tous les membres de votre équipe peuvent utiliser ce modèle une fois que vous l'avez partagé. Voulez-vous continuer?",
  share_template: 'Partager le modèle',
  enter_subject: 'Veuillez saisir le sujet',
};

const emails = {
  send_email_subject: "Envoyez l'e-mail maintenant ?",
  send_email: 'Envoyer un e-mail',
};

const tags = {
  tags_title: 'Ajouter ou supprimer des balises',
  no_tags_found_title: 'Aucune balise définie pour le moment.',
  no_tags_found_sub_title: 'Allez-y, ajoutez votre premier tag ! 🔥',
  tags_placeholder: 'Entrer une balise',
  tags_removeMode: 'Les balises appliquées seront affichées ici',
  add_tag: '+ ajouter des balises',
  prospects_updated: 'Prospects Mis à jour avec succés',
};

const header = {
  mark_all_as_read: 'Tout marquer comme lu',
  resume: 'Reprendre',
  reconnect: 'Se reconnecter',
  notifications: 'Notifications',
  profile_settings: 'Paramètres de profil',
  feature_request: 'Demande de fonctionnalité',
  become_our_affiliate: 'Devenez notre affilié',
  help: 'aider',
  logout: 'Se déconnecter',
  emailDisconnected: 'E-mail déconnecté',
  sequencePaused: 'Séquence en pause',
  changeLog: 'Quoi de neuf',
  scheduleADemo: 'Planifier une démo',
  noNotifications: 'Aucune nouvelle notification pour le moment.',
};

const blacklist = {
  valid_domain: 'Entrez le nom de domaine valide au format domaine.com',
  blacklist_domains: 'Domaines sur liste noire',
  blacklist_domain_sub_header:
    "Empêchez les e-mails d'aller aux prospects dans des domaines spécifiques.",
  blacklist_domain_instruction:
    'Utilisez une virgule pour séparer plusieurs domaines. Par exemple : gmail.com, facebook.org, outlook.com',
};

const profileProgress = {
  // keeping object keys for profile progress steps to be same
  // as receiving in api response payload
  'watch-video': 'Regarder la vidéo',
  'connect-email': "Connecter l'e-mail",
  'create-sequence': 'Créer une séquence',
  'add-prospects': 'Ajouter des prospects',
  'activate-sequence': 'Activer la séquence',
  hello: 'Bonjour',
  getting_started: 'Commencer',
  you_are_gonna_be_a_saleshandy_superstar: 'Tu vas être une superstar vendeuse',
  completed: 'Complété',
  nice_work: 'Nice Work',
  going_good: 'Ça va bien',
  excellent_work: 'Excellent travail',
  activate_sequence_message:
    'Activez cette bascule pour activer la séquence et commencer à contacter vos prospects.',
  learn_create_sequence: 'Apprenez à créer une séquence en 4 minutes',
};

const watchVideoModal = {
  create_sequence: 'Créer une séquence',
  watch_later: 'Regarder plus tard',
  watch_video_description_1:
    'Avant de commencer, nous vous recommandons de regarder cette vidéo pour apprendre',
  watch_video_description_2:
    "comment configurer et exécuter votre première séquence d'e-mails avec Saleshandy.",
};

const apiKey = {
  api_key_home_part_1:
    'Vos clés API doivent être traitées de manière aussi sécurisée que tout autre mot de passe. Référer ',
  api_key_home_part_2: `documentation de l'API`,
  api_key_home_part_3: ` pour l'intégration`,
  enter_your_label: `Entrez votre étiquette`,
  label: 'Étiqueter',
  invalid_label_input: `Entrée d'étiquette non valide`,
  only_letters_allowed:
    'Seuls les caractères, les chiffres et les tirets sont autorisés',
  label_less_than: 'Le libellé doit comporter moins de 25 caractères',
  api_keys_empty: 'Les clés API sont vides',
  please_add_api_keys: 'Veuillez ajouter des clés API',
};

const sequencePauseMessages = {
  bounce_limit_reached:
    "Cette séquence est interrompue en raison du nombre élevé d'e-mails rejetés. Nous vous suggérons de vérifier les prospects avant de reprendre la séquence.",
  email_account_disconnected:
    'Cette séquence est suspendue car votre compte de messagerie a été déconnecté. Veuillez visiter Paramètres pour vous reconnecter et recommencer à envoyer des e-mails.',
  plan_expired: `Cette séquence est suspendue car votre forfait a expiré. 
    Veuillez mettre à niveau votre compte pour reprendre la séquence si vous n'avez pas encore mis à niveau votre compte.`,
  manually_paused:
    'Vous avez mis manuellement votre séquence en pause. Activez votre séquence pour recommencer à envoyer des e-mails.',
  email_sending_limit_reached:
    "Cette séquence est suspendue car vous avez atteint la limite d'envoi de votre fournisseur. Veuillez reprendre après 24 heures de la date de pause de votre séquence.",
  trial_quota_exhausted:
    "La séquence est suspendue car vous avez épuisé le quota du plan d'essai.",
  plan_paused:
    'Votre abonnement est suspendu en raison de quoi vos séquences ont été suspendues.',
  agency_client_deleted:
    'Cette séquence est en pause car le propriétaire de la séquence a été supprimé. Veuillez reprendre la séquence avec un bouton bascule.',
  email_account_deleted:
    'Cette séquence est interrompue car le compte de messagerie a été supprimé.',
  plan_downgraded:
    'Vous êtes actuellement sur le plan de démarrage en raison duquel toutes vos séquences sont en pause. Mettez à jour votre forfait existant pour commencer à envoyer des e-mails.',
};

const emailEditorModals = {
  test_email_title: 'Envoyer un e-mail test',
  test_email_content:
    'Un e-mail de prévisualisation sera envoyé à cette adresse e-mail. Vous pouvez également envoyer à plusieurs adresses e-mail en les séparant par une virgule. Le nombre maximum autorisé est de 5 e-mails.',
  email_account_note_content:
    "Remarque : Vous risquez de perdre vos données de messagerie actuellement non enregistrées si vous cliquez sur se connecter maintenant. Cliquez sur Annuler pour enregistrer le texte de l'e-mail et éviter de perdre des données.",
  email_account_disconnected_title: 'Compte de messagerie déconnecté',
  email_account_disconnected_content:
    'Votre compte de messagerie est déconnecté. Veuillez connecter votre compte de messagerie pour continuer à envoyer des e-mails.',
  email_account_not_connected_title: 'Compte de messagerie non connecté',
  email_account_not_connected_content:
    'Veuillez sélectionner un compte de messagerie dans les paramètres de séquence pour continuer à envoyer des e-mails.',
  email_not_added_title: 'Compte de messagerie non ajouté',
  email_not_added_content:
    "Votre compte de messagerie n'a pas été ajouté. Veuillez connecter votre compte de messagerie pour continuer à envoyer des e-mails.",
  confirmation_modal_title:
    "Vous souhaitez fermer l'étape sans enregistrer les modifications?",
  confirmation_modal_content:
    "La fermeture de l'éditeur supprimerait les modifications non enregistrées apportées dans l'e-mail. Si vous souhaitez enregistrer ces modifications, cliquez sur Annuler, puis sur le bouton Enregistrer.",
};

const coldEmailTips = {
  content_guide: 'Guide de contenu',
  cold_email_tips: 'Conseils pour les e-mails froids',
  cold_email_tip_1_title:
    "Personnalisez la ligne d'objet à l'aide de merge tags",
  cold_email_tip_1_content:
    "Un e-mail avec des lignes d'objet personnalisées obtient des taux d'ouverture 6% plus élevés.",
  cold_email_tip_2_title:
    "Ajouter un texte de pré-en-tête pour résumer l'e-mail",
  cold_email_tip_2_content:
    "Les e-mails avec des pré-en-têtes pertinents obtiennent des taux d'ouverture 10% plus élevés.",
  cold_email_tip_3_title: 'Apprenez à rédiger des e-mails froids efficaces',
  cold_email_tip_3_content_1: 'Cliquez sur',
  cold_email_tip_3_content_2: 'ici',
  cold_email_tip_3_content_3:
    'pour lire le guide ultime pour écrire des e-mails froids',
  cold_email_tip_4_title: 'Utilisez Spintax pour maximiser la délivrabilité',
  cold_email_tip_4_content_1:
    'Spintax vous permet de générer plusieurs variantes dans un seul e-mail.',
  cold_email_tip_4_content_2: 'Apprendre encore plus',
};

const emailSuggestions = {
  subject_title: 'Longueur du sujet',
  character: 'Personnage',
  characters: 'Personnages',
  subject_helper_text: '30-60 caractères suggérés.',
  personalization_title: 'Personnalisation',
  merge_tag: 'balise de fusion',
  merge_tags: 'balises de fusion',
  personalization_helper_text: '2 balises de fusion ou plus suggérées.',
  link_title: 'Liens',
  link: 'lien',
  links: 'liens',
  link_helper_text: 'Nous suggérons une utilisation minimale.',
  spammy_title: 'Spam',
  word: 'mot',
  words: 'mots',
  spammy_helper_text: 'Nous suggérons une utilisation minimale.',
};

const needHelpCta = {
  need_help: "Besoin d'aide?",
  cta_1: 'Tu peux',
  cta_2: 'initier une conversation',
  cta_3: "pour obtenir de l'aide de notre part.",
  cta_4: 'planifier un appel',
};

const customTrackingDomainMessages = {
  turn_on_line_tracking: 'Activer le suivi des liens?',
  title: 'Suivi des liens désactivé',
  domain_not_set_part_1:
    "Nous vous recommandons de configurer un domaine personnalisé pour suivre les liens à l'aide d'URL de marque.",
  domain_not_set_part_2:
    'Il vous aide à améliorer le taux de livraison des e-mails.',
  domain_not_set_part_3: 'Voulez-vous toujours activer le suivi des liens ?',
  domain_set_part_1:
    'Nous vous recommandons de désactiver le suivi des liens pour un meilleur taux de livraison des e-mails.',
  domain_set_part_2:
    "L'activation du suivi des liens peut réduire le taux d'ouverture de 5%.",
  domain_set_part_3: "Voulez-vous toujours l'activer?",
  delete_custom_domain_title: 'Voulez-vous supprimer ce domaine personnalisé?',
  delete_default_custom_domain_content_1:
    'Vous essayez de supprimer le domaine de suivi personnalisé par défaut. Lors de la suppression, ce système définira un nouveau domaine de suivi personnalisé par défaut.',
  delete_default_custom_domain_content_2: `La suppression d'un domaine de suivi personnalisé peut nuire à la délivrabilité de vos e-mails.`,
  delete_default_custom_domain_content_3:
    'Vous essayez de supprimer le domaine de suivi personnalisé par défaut. Lors de la suppression, le système devra définir un nouveau domaine de suivi personnalisé par défaut.',
  delete_default_custom_domain_content_4:
    'Veuillez ajouter un nouveau domaine de suivi personnalisé afin de le supprimer.',
  delete_custom_domain_title_2:
    'Vous ne pouvez pas supprimer ce domaine personnalisé !',
};

const planSummary = {
  userAccounts: 'Comptes utilisateur',
  emailAccounts: 'Comptes mail',
  newUserSeats: 'Nouveaux sièges utilisateur',
  newEmailSeats: 'Nouveaux postes de messagerie',
};

const timeIntervalSettings = {
  time_interval_error:
    "L'intervalle de temps doit être compris entre 20 et 2000 secondes",
  same_time_interval_error:
    'Il devrait y avoir un intervalle minimum de 10 secondes',
  time_interval_warning:
    "L'envoi d'un trop grand nombre d'e-mails en une heure peut entraîner la suspension ou la mise sur liste noire du compte. Nous suggérons de maintenir cet intervalle entre 60 et 190 secondes pour une délivrabilité optimale.",
};

const rampUpSettings = {
  emails_per_day_error:
    "La valeur ne peut pas être supérieure au nombre maximal d'e-mails par jour.",
  emails_per_day_percent_error:
    'La valeur en pourcentage doit être comprise entre 1 et 100.',
  daily_rampup_title: 'Daily Ramp-Up (pour réchauffer le compte de messagerie)',
  daily_rampup_tooltip_text_1:
    "Nous vous suggérons d'activer cette option pour augmenter progressivement le quota d'envoi quotidien car cela vous aide à réchauffer votre compte de messagerie.",
  daily_rampup_tooltip_text_2:
    'Vous pouvez le désactiver à tout moment pour passer à un quota quotidien statique.',
  daily_rampup_tooltip_text_3: 'Cliquez ici',
  daily_rampup_tooltip_text_4: 'pour apprendre plus.',
  emails_per_day_start: 'Commencer avec',
  emails_per_day_end: 'e-mails par jour.',
  emails_per_day_suggestion: '(Nous suggérons de commencer par 5)',
  emails_per_day_percent_start: 'Augmenté de',
  emails_per_day_percent_end: 'pour cent par jour.',
  emails_per_day_percent_suggestion: '(Nous suggérons 10-20%)',
  rampup_results: "Le nombre total d'e-mails à envoyer aujourd'hui est de",
  disable_rampup_confirmation_title:
    'Êtes-vous sûr de vouloir désactiver Ramp-Up?',
  disable_rampup_confirmation_content_1:
    "La désactivation de Ramp-up au milieu peut augmenter considérablement votre activité d'envoi d'e-mails,",
  disable_rampup_confirmation_content_2:
    "ce qui peut affecter les performances de votre séquence. Nous vous recommandons d'autoriser la montée en puissance progressivement",
  disable_rampup_confirmation_content_3:
    "augmentez l'envoi jusqu'à ce qu'il atteigne le nombre maximum d'e-mails par jour que vous avez défini.",
  ramp_up_limit_reached:
    "L'accélération a été désactivée après avoir atteint le nombre maximal d'e-mails par jour.",
  save_rampup_confirmation_title: 'Voulez-vous vraiment modifier la valeur?',
  save_rampup_confirmation_content_1:
    "Une fois les modifications enregistrées, les paramètres d'envoi mis à jour seront applicables à partir de",
  save_rampup_confirmation_content_2: 'À partir de.',
  save_rampup_confirmation_content_3: "Voulez-vous l'enregistrer?",
  today: "aujourd'hui",
  tomorrow: 'demain',
};

const emailAccountTableHeader = {
  sender: 'Expéditrice',
  sending_limit: "Limite d'envoi",
  email_score: 'Note par e-mail',
  connected_by: 'Connecté par',
  actions: 'Actions',
};

const emailHealthScore = {
  email_disconnected_message:
    "L'e-mail est déconnecté. Connectez-le pour un score de santé précis.",
  learn_how_to_set_this: 'Apprenez à régler ceci',
  learn_how_to_warm_up: 'Apprenez à vous échauffer',
  spf_result_message_1:
    "SPF (Sender Policy Framework) est un enregistrement essentiel pour l'authentification des e-mails. ",
  spf_result_message_2:
    'Cela affecte considérablement la délivrabilité de vos e-mails. ',
  spf_result_message_3:
    'Nous vous suggérons fortement de le configurer avant de commencer à envoyer des e-mails. ',
  ctd_error_result_message_1:
    'Le domaine de suivi personnalisé est une fonctionnalité ',
  ctd_error_result_message_2:
    'qui vous permet de remplacer le domaine de suivi générique par votre domaine ',
  ctd_error_result_message_3:
    'qui vous aide éventuellement à améliorer la délivrabilité de vos e-mails. ',
  ctd_error_result_message_4:
    'Nous vous recommandons vivement de le configurer avant de commencer à envoyer des e-mails. ',
  dmarc_result_message_1:
    "DMARC est une méthode d'authentification de messagerie standard, et il aide les administrateurs de messagerie à empêcher les pirates ",
  dmarc_result_message_2:
    "et d'autres attaquants d'usurper leur organisation et leur domaine. ",
  dmarc_result_message_3:
    'Il est suggéré de le configurer pour des résultats de messagerie optimaux. ',
  email_age_result_message_1:
    'Votre compte de messagerie est suffisamment ancien pour obtenir une délivrabilité décente.',
  email_age_result_message_2:
    " Nous vous suggérons d'utiliser notre fonction d'accélération des e-mails si vous souhaitez vous bâtir une bonne réputation d'expéditeur.",
  email_age_error_result_message_1:
    'Les comptes de messagerie nouvellement créés sont vulnérables aux filtres anti-spam. ',
  email_age_error_result_message_2:
    'Le fournisseur de messagerie peut également les restreindre si vous essayez de ',
  email_age_error_result_message_3:
    "envoyer un grand nombre d'e-mails à partir d'un compte de messagerie relativement nouveau.",
  email_age_error_result_message_4:
    "Nous suggérons aux utilisateurs de conserver le quota d'envoi le plus bas et ",
  email_age_error_result_message_5:
    "utilisez notre fonction d'accélération des e-mails pour augmenter progressivement le quota d'envoi.",
  email_age_error_result_message_6:
    "Si vous pensez que votre e-mail est ancien, veuillez déconnecter l'e-mail &",
  email_age_error_result_message_7:
    "reconnectez l'e-mail et vérifiez à nouveau le score de santé.",
  check_deliverability_loading_1:
    'Nous vérifions le dernier score de santé pour ce compte de messagerie ',
  check_deliverability_loading_2:
    "ce qui peut prendre jusqu'à 2 minutes. Veuillez patienter pendant que nous récupérons les détails. ",
  check_deliverability_1:
    "Nous vous permet d'évaluer la santé de votre compte de messagerie sur ",
  check_deliverability_2:
    "divers aspects pour savoir si c'est assez bon pour envoyer des e-mails. ",
  check_deliverability_3:
    'Nous soulignons également les problèmes que vous devez résoudre pour obtenir les meilleurs résultats.',
  check_email_Score: 'Vérifier le score des e-mails',
  need_help_to_improve_your_Score: "Besoin d'aide pour améliorer votre score ?",
  need_help_content:
    'Améliorez le score de santé de vos e-mails en planifiant un appel avec notre expert en délivrabilité des e-mails.',
  talk_to_deliverability_expert: 'Parlez à des experts en délivrabilité',
  check_deliverability_score: 'Vérifier le score de délivrabilité',
  email_health_score: 'Score de santé des e-mails',
  last_checked_at: 'Dernière vérification à',
  score_factor_details: 'Détails du facteur de score',
  re_check_health_score: 'Revérifier le score de santé',
  check_deliverability_manually_fetch:
    "Saleshandy n'a pas pu récupérer l'état de santé de cet e-mail. Veuillez réessayer.",
  changes_updated_successfully: 'Modifications mises à jour avec succès.',
  daily_ramp_up_settings_updated_successfully:
    'Paramètres de montée en puissance quotidiens mis à jour avec succès',
  sending_limit_updated_text_1:
    "Limite d'envoi mise à jour. Désormais, vous pouvez augmenter le nombre total d'e-mails par jour jusqu'à ",
  sending_limit_updated_text_2: ' e-mails.',
  lower_value_limit_then_ramp_up_limit_error:
    "Cette valeur ne doit pas être inférieure à la valeur de montée en puissance des e-mails à envoyer aujourd'hui.",
  soft_limit_modal_content_1: "Nous vous recommandons d'envoyer moins de",
  soft_limit_modal_content_2: "e-mails par jour d'un",
  soft_limit_modal_content_3: 'libre',
  soft_limit_modal_content_4: 'Compte.',
  soft_limit_modal_content_5:
    "Veuillez connecter un e-mail professionnel pour des limites d'envoi plus élevées.",
  soft_limit_modal_content_6: "Je comprends le risque d'envoyer plus de",
  soft_limit_modal_content_7: 'e-mails',
  soft_limit_modal_content_8: 'à partir de ce compte de messagerie',
  soft_limit_modal_content_9: 'e-mails par jour',
  email_per_day_success_message:
    "Nombre total d'e-mails par jour mis à jour avec succès",
  zero_limit_error: "La limite d'envoi doit être d'au moins 1",
  learn_how_to_set: 'Apprenez à régler',
  hard_limit_error_content_1:
    'peut bloquer votre compte si vous envoyez plus de',
  hard_limit_error_content_2: 'peut être affecté si vous envoyez plus de',
  hard_limit_error_content_3: 'e-mails. Nous recommandons',
  hard_limit_error_content_4: "pour envoyer plus d'e-mails.",
  hard_limit_error_content_5: 'connecter un autre compte de messagerie',
  access_token_error_description_1: 'Votre compte a été déconnecté',
  access_token_error_description_2:
    'Veuillez le reconnecter pour récupérer le dernier score de santé.',
};

const prospectFilterLabels = {
  filters: 'Filtres',
  owners: 'Propriétaires',
  tags: 'Mots clés',
  sequence: 'Séquence',
  status: 'Statut',
  verification_status: 'Statut de vérification',
  created_date: 'Date de création',
};

const unsubscribeLinkModal = {
  unsubscribe_link_modal_title:
    'Êtes-vous sûr de vouloir supprimer le lien de désinscription ?',
  unsubscribe_link_modal_message_one:
    'Nous vous recommandons fortement de conserver le lien de désinscription dans les e-mails afin de réduire les plaintes de spam par les destinataires.',
  unsubscribe_link_modal_message_two:
    'La désactivation de cette option peut affecter négativement la délivrabilité de votre e-mail.',
};

const saleshandyUsageLabels = {
  sales_team: 'Équipe de vente',
  marketing_team: 'Équipe de marketing',
  agency_consultant: 'Agence / Conseil',
  recruiters: 'Recruteurs',
  other: 'Autre',
};

const teamSizeLabels = {
  just_me: 'juste moi',
  team_2_5: '2-5',
  team_5_20: '5-20',
  team_20_plus: '20+',
};

const industryLabels = {
  it_services: 'services informatiques',
  saas: 'saas',
  human_resources: 'ressources humaines',
  marketing_advertising: 'publicité Marketing',
  internet: "l'Internet",
  other: 'autre',
};

const SMTPIMAPConnection = {
  issue_with_smtp_connection: 'Problème avec la connexion SMTP.',
  issue_with_imap_connection: 'Problème avec la connexion IMAP.',
};

const tipsToConnectSMTPIMAPEmail = {
  tips_title: 'Conseils pour connecter avec succès votre compte de messagerie:',
  smtp_tips_one:
    'Assurez-vous que votre compte de messagerie est activé pour SMTP.',
  smtp_tips_two:
    "Assurez-vous que 2FA n'est pas activé sur votre compte de messagerie.",
  smtp_tips_three:
    "Si 2FA est activé, veuillez utiliser le mot de passe de l'application au lieu du mot de passe de l'e-mail.",
  imap_tips_one:
    'Assurez-vous que votre compte de messagerie est activé pour IMAP.',
  imap_tips_two:
    "Assurez-vous d'utiliser les informations d'identification de votre compte de messagerie pour la connexion IMAP.",
  smtp_gmail_tips_one_a: 'Nous vous recommandons vivement de créer un',
  smtp_gmail_tips_one_b: "mot de passe de l'application",
  smtp_gmail_tips_one_c:
    'pour connecter votre compte Gmail via la méthode SMPT/IMAP.',
  smtp_gmail_tips_two:
    "L'activation de la vérification en 2 étapes est obligatoire pour créer un mot de passe de l'application pour un compte Gmail.",
  smtp_gmail_tips_three_a: 'Cliquez ici',
  smtp_gmail_tips_three_b:
    "pour savoir comment créer et utiliser un mot de passe d'application pour connecter votre compte Gmail via la méthode SMTP/IMAP.",
  smtp_gmail_tips_three_c: 'Apprendre de la vidéo.',
  imap_gmail_tips_one:
    "Assurez-vous qu'IMAP est activé pour cette boîte aux lettres Gmail.",
  imap_gmail_tips_two:
    "Vous pouvez utiliser le même mot de passe d'application pour connecter l'IMAP.",
};

const mailboxEmails = {
  track_your_first_email: 'Suivre votre premier e-mail',
  install_saleshandy_connect:
    "Installez l'extension Saleshandy Connect et commencez à suivre les e-mails individuels.",
  see_how_extension_works: "Découvrez comment fonctionne l'extension",
  install_chrome_extrension: "Installer l'extension Chrome",
  delete_mailbox_email: "Voulez-vous supprimer cette entrée d'e-mail?",
  delete_mailbox_email_description:
    'Toutes les informations relatives au suivi des e-mails pour cet e-mail seront effacées et ne pourront pas être récupérées.',
  email_deleted_successfully: "L'e-mail a été supprimé avec succès.",
  notification_turned_on_tooltip_text: 'Désactiver les notifications',
  notification_turned_off_tooltip_text: 'Désactiver les notifications',
  notification_turned_on_successfully:
    'La notification a été activée avec succès.',
  notification_turned_off_successfully:
    'La notification a été désactivée avec succès.',
};

const chromeExtensionSettings = {
  desktop_notification: 'Notifications de bureau',
  track_email_by_my_team: 'Suivre les e-mails de mon équipe',
  track_email_by_my_team_tooltip:
    "Cette option vous permet d'activer/désactiver le suivi des e-mails pour les e-mails individuels envoyés aux membres de votre équipe.",
  test_notification: "Avis d'essai",
  test_notification_tooltip:
    'Si vous rencontrez des problèmes avec les notifications,',
  link_click_here: 'Cliquez ici',
  notification_body:
    "Ceci est un exemple de notification pour l'activité Saleshandy.",
  notification_not_supported:
    'Ce navigateur ne prend pas en charge les notifications de bureau.',
  notification_denied_message_1:
    'Vous avez refusé les notifications pour Saleshandy,',
  notification_denied_message_2:
    'pour un guide étape par étape pour réactiver les notifications.',
  settings_saved: 'Paramètres sauvegardés.',
};

const emailWarmup = {
  start_with_email_per_day: 'Commencez avec des e-mails/jour',
  increase_by_emails_every_day: 'Augmenter par e-mails tous les jours',
  maximum_emails_sent_per_day: "Maximum d'emails à envoyer par jour",
  reply_rate: 'Taux de réponse',
  sender_name: "Nom de l'expéditeur",
  recommended: 'Recommandée',
  zero_value_error_1: 'La valeur ne peut pas être inférieure à',
  zero_value_error_2: 'ou en fraction.',
  max_value_error: 'La valeur ne doit pas dépasser',
  max_value_custom_error: 'Votre forfait actuel limite la valeur maximale à',
  char_limit_error: 'La valeur doit être comprise entre 3 et 80 caractères.',
  email_warmup_paused_successfully:
    'Le préchauffage des e-mails a été suspendu avec succès.',
  email_warmup_enabled_successfully: 'Email Warm-up activé avec succès.',
  reconnect_email_account_title: 'Votre compte de messagerie est déconnecté!',
  reconnect_email_account_content:
    "Vous ne pouvez pas activer le préchauffage des e-mails pour un compte de messagerie déconnecté. Veuillez d'abord le reconnecter.",
  disconnect_email_account_title:
    'Voulez-vous désactiver le préchauffage des e-mails pour ce compte de messagerie?',
  disconnect_email_account_content:
    "Email Warm-up vous permet de construire et de maintenir une réputation d'expéditeur optimale. Le supprimer peut avoir un impact sur la délivrabilité à l'avenir.",
  email_account_is_connected: 'Le compte de messagerie est connecté.',
  email_account_is_disconnected: 'Le compte de messagerie est déconnecté.',
  email_warmup_empty_list_title:
    "Construisez votre réputation d'e-mail avec notre échauffement des e-mails et ne tombez plus jamais dans le spam.",
  email_warmup_empty_list_desc:
    'Ajoutez votre premier compte de messagerie pour commencer.',
  see_how_warmup_works: "Découvrez comment fonctionne l'échauffement",
  add_new_email_account: 'AAjouter un compte de messagerie',
  email_warmup_settings_updated_successfully:
    'Les paramètres de préchauffage des e-mails ont été mis à jour avec succès.',
  deliverability: 'Deliverability',
  total: 'Total',
  inbox: 'Inbox',
  spam: 'Spam',
  received_today: "Reçu aujourd'hui",
  received_in_last_seven_days: 'Reçu au cours des 7 derniers jours',
  received_in_last_thirty_days: 'Reçu au cours des 30 derniers jours',
  sent_today: "Envoyé aujourd'hui",
  sent_in_last_seven_days: 'Envoyé au cours des 7 derniers jours',
  sent_in_last_thirty_days: 'Envoyé au cours des 30 derniers jours',
  click_here_to_turn_on_warmup:
    "Cliquez ici pour activer l'échauffement des e-mails pour ce compte de messagerie.",
  click_here_to_turn_off_warmup:
    "Cliquez ici pour désactiver l'échauffement des e-mails pour ce compte de messagerie.",
  running: 'Fonctionnement',
  warmup_settings: "Paramètres d'échauffement",
  enable_email_warmup_title:
    "Activer l'échauffement des e-mails pour ce compte de messagerie?",
  enable_email_warmup_desc_1:
    "L'activation de l'échauffement permettrait à Saleshandy d'envoyer des e-mails d'échauffement à partir de ce compte de messagerie,",
  enable_email_warmup_desc_2:
    "et vous recevrez également des e-mails d'échauffement d'autres utilisateurs de Saleshandy.",
  dont_show_this_message_again: "Ne m'affichez plus ce message.",
};

const noSeatAvailableEmail = {
  no_seat_available_email_subject_1:
    'Les sièges du compte de messagerie Saleshandy sont épuisés.',
  no_seat_available_email_subject_2:
    'Besoin de mettre à niveau pour ajouter plus de postes de messagerie',
  no_seat_available_email_body_1:
    'Il semble que notre abonnement Saleshandy soit épuisé',
  no_seat_available_email_body_2:
    'le quota de comptes de messagerie connectés selon le',
  no_seat_available_email_body_3:
    'abonnement et par conséquent, je ne parviens pas à connecter mon e-mail.',
  no_seat_available_email_body_4:
    "Je vous demanderais de mettre à niveau l'abonnement actuel pour ajouter des sièges de compte de messagerie supplémentaires.",
};

const errorBoundary = {
  error_boundary_title:
    'Oops! Il semble que quelque chose est tombé en panne de notre côté',
  error_boundary_sub_text_one:
    "L'équipe d'ingénierie est automatiquement notifiée.",
  error_boundary_sub_text_two:
    'Nous vous demandons de réenregistrer dans un certain temps ou',
  return_to_application: "Retour à l'application",
};

const emailAccountType = {
  google: 'Gmail et Google Workspace',
  microsoft: 'Échange, O365, Outlook et Hotmail',
  smtp_imap: 'Tout autre compte de fournisseur de service de messagerie',
  saleshandy:
    'Synchronisez tous les comptes de messagerie de Saleshandy avec TrulyInbox',
};

const nativeAttachment = {
  unsupported_file_type:
    "Le type de fichier que vous essayez d'ajouter n'est pas pris en charge.",
  email_content_size_exceeds:
    'La taille totale de votre e-mail ne doit pas dépasser',
  number_of_attachments_allowed_1: 'Plus de',
  number_of_attachments_allowed_2:
    'les pièces jointes ne peuvent pas être téléchargées.',
  attachment_removed_successfully:
    'La pièce jointe a été supprimée avec succès.',
};

const adminSettings = {
  are_you_sure_you_want_to_turn_this_on:
    'Voulez-vous vraiment activer cette option?',
  are_you_sure_you_want_to_turn_this_off:
    'Voulez-vous vraiment désactiver cette option?',
  turning_this_toggle_on_will_allow_you:
    "L'activation de cette bascule vous permettra",
  turning_this_toggle_off_will_not_allow_you:
    'La désactivation de cette bascule ne vous permettra pas',
  to_have_one_prospect_in_multiple_sequences_simultaneously:
    "d'avoir un prospect dans plusieurs séquences simultanément.",
  and_your_team_member_to_verify_prospects_while_importing_them:
    'à vous et au membre de votre équipe, de vérifier les prospects lors de leur importation.',
  change_will_not_affect:
    "Ce changement n'affectera pas les prospects existants déjà présents dans plusieurs séquences.",
  change_will_apply_to_future_imports:
    "Ce changement s'appliquera à toutes vos futures importations.",
};

// Outreach Basic Restriction
const outreachBasicRestrictionMsg = {
  team_invite_msg_1:
    "Votre forfait actuel ne vous permet pas d'inviter les membres de l'équipe.",
  team_invite_msg_2:
    "Veuillez passer à Outreach Pro ou à des plans supérieurs pour inviter un nombre illimité de membres de l'équipe.",
  api_key_msg_1:
    "Votre forfait actuel ne vous permet pas d'utiliser la clé API.",
  api_key_msg_2:
    'Veuillez passer à Outreach Pro ou à des plans supérieurs pour créer des clés API illimitées pour des intégrations transparentes.',
  step_variant_msg_1:
    'Outreach Basic ne vous permet pas de créer plusieurs variantes en une seule étape.',
  step_variant_msg_2:
    'Veuillez passer aux plans Outreach Pro ou supérieur pour créer plus de variantes.',
};

// eslint-disable-next-line import/prefer-default-export
export const fr = {
  labels: {
    selected: 'Choisie',
    apply: 'Appliquer',
    discard: 'Jeter',
    select_all: 'Tout sélectionner',
    reports: 'Rapports',
    duration: 'Durée',
    this_month: 'Ce mois-ci',
    last_month: 'Le mois dernier',
    last_15_days: '15 derniers jours',
    last_7_days: '7 derniers jours',
    this_week: 'Cette semaine',
    last_week: 'La semaine dernière',
    yesterday: 'Hier',
    today: "Aujourd'hui",
    add_email_account: 'Ajouter un compte de messagerie',
    add_prospects: 'Ajouter des prospects',
    add_prospect: 'Ajouter un prospect',
    do_later: `je le ferais plus tard`,
    select_email_service:
      'Sélectionnez le service de messagerie que vous utilisez',
    sequence_settings: 'Paramètres de séquence',
    save: 'Sauvegarder',
    schedule: 'Horaire',
    email_account: 'Compte email',
    safety_settings: 'Paramètres de sécurité',
    step: 'Marcher',
    prospects: 'Perspectives',
    prospectsAdded: 'Perspectives ajoutées',
    emailsSent: 'Courriels envoyés',
    opened: 'Ouvert',
    clicked: 'Cliqué',
    link_clicked: 'Lien Cliqué',
    sent: 'Envoyé',
    replied: 'A répondu',
    bounced: 'Rebondi',
    unsubscribed: 'Désabonné',
    activity: 'Activité',
    sequences: 'Séquences',
    profile: 'Profil',
    cancel: 'Annuler',
    okay: "D'accord",
    verify: 'Vérifier',
    export: 'Exportation',
    emails: 'Courriels',
    email: 'Courriel',
    totalProspects: 'Perspectives totales',
    no: 'Non',
    yes: 'Oui',
    delete: 'Effacer',
    title: 'Titre',
    daily: 'Du quotidien',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    quarterly: 'Trimestrielle',
    all: 'Tout',
    no_data_available: 'Pas de données disponibles!',
    create_and_add_to_sequence: 'Créer et ajouter à la séquence',
    day: 'journée',
    days: 'jours',
    upgrade: 'Améliorer',
    upgrade_now: 'Mettre à jour maintenant',
    upgrade_plan: 'Plan de mise à niveau',
    downgrade_plan: 'Plan de rétrogradation',
    current_plan: 'Plan actuel',
    add_more_slots: "Ajouter plus d'emplacements",
    sub_total: 'Sous-total',
    total_amount: 'Montant total',
    total_amount_to_pay_today: "Montant total à payer aujourd'hui",
    discount: 'Remise',
    total_after_discount: 'Total après remise',
    total_per_year: 'Total par an',
    total_per_month: 'Total par mois',
    cancel_subscription: "Annuler l'abonnement",
    reactivate_subscription: "Réactiver l'abonnement",
    resume_subscription: "Reprendre l'abonnement",
    bill_summary: 'Résumé de la facture',
    plan_name: 'Nom du régime',
    pay_now: 'Payez maintenant',
    monthly_billing: 'Facturation mensuelle',
    annually_billing: 'Facturation annuelle',
    upgrade_subscription: "Mettre à niveau l'abonnement",
    modify_subscription: "Modifier l'abonnement",
    search_hit_enter: 'rechercher et appuyer sur entrée',
    search: 'Chercher..',
    done: 'terminé',
    ...header,
    payment_failed: 'Paiement échoué',
    retry_payment: 'Retry Payment',
    contact_support: 'Contactez le support',
    Contact_Support: 'Contactez le support',
    i_will_use_legacy_version: 'Je vais utiliser la version héritée',
    clear_all: 'Tout effacer',
    apply_filters: 'appliquer des filtres',
    create: 'Créer',
    close: 'Fermer',
    copy: 'Copie',
    create_api_key: 'Créer une clé API',
    unsubscribe_success_message:
      'Le message de désabonnement a été enregistré avec succès.',
    unsubscribe_error:
      'La balise de désabonnement est requise pour avoir le texte de désabonnement personnalisé.',
    have_a_promo_code: 'Avez vous un code de réduction?',
    remove_discount_code: 'Supprimer le code de réduction',
    need_help: "Besoin d'aide?",
    unsubscribe: 'Se désabonner',
    remove_delete: 'Supprimer/Supprimer',
    send: 'Envoyer',
    connect_now: 'Se connecter maintenant',
    continue: 'Continuez',
    unsubscribe_prospect: 'Désinscrire les prospects',
    delete_prospect: 'Supprimer le prospect',
    api_key: 'clé API',
    my_profile: 'Mon profil',
    email_accounts: 'Comptes mail',
    schedules: 'Des horaires',
    team: 'Équipe',
    custom_fields: 'Les champs personnalisés',
    system_fields: 'Champs système',
    add_prospect_fields: 'Ajouter des champs de prospect',
    prospect_label_error: `L'étiquette doit être inférieure ou égale à 100 caractères.`,
    prospect_fallback_text_error:
      'Le texte de remplacement doit être inférieur ou égal à 255 caractères.',
    prospect_fields: 'Champs de prospect',
    custom_tracking_domain: 'Domaine de suivi personnalisé',
    admin_settings: `Paramètres d'administration`,
    blacklist_domains: 'Domaines sur liste noire',
    billing_and_subscription: 'Facturation & abonnement',
    prospect_search_placeholder: 'Chercher...',
    sequence: 'Séquence',
    status: 'Statut',
    prospect_selected: 'Prospects sélectionné(s).',
    sequence_statistics: 'Statistiques de séquence',
    purchase_credits: "Crédits d'achat",
    buy_now: 'Acheter maintenant',
    contact_admin: "Contacter l'administrateur",
    buy_credits: 'Acheter des crédits',
    verification_status_settings: 'Statut de vérification',
    verify_now: 'Vérifiez maintenant',
    verifying: 'Verifying',
    already_paid: 'Déjà payé',
    total_to_be_paid: 'Total à payer',
    days_billing: 'jours de facturation',
    save_and_pay: 'Économisez et payez',
    note: 'Noter',
    ...planSummary,
    pre_header_placeholder: 'Veuillez entrer le pré-en-tête ....',
    pre_header: 'pré-en-tête',
    pre_header_label: 'définir le pré-en-tête',
    pre_header_recommendation:
      'Recommandation : Utilisez de 40 à 130 caractères dans le pré-en-tête.',
    pre_header_error: `Vous n'êtes pas autorisé à entrer plus de 150 caractères`,
    pre_header_tooltip_1: `Un pré-en-tête est un texte d'e-mail affiché lorsqu'un e-mail non ouvert est prévisualisé dans la boîte de réception.`,
    pre_header_tooltip_2: `Il s'affiche après la fin de la ligne d'objet. La visibilité peut varier pour chaque client de messagerie.`,
    create_new_prospect: 'Créer un nouveau prospect',
    email_sending_limit_header: `Nombre total d'e-mails par jour`,
    email_sending_limit_caption: `Nombre maximum d'e-mails pouvant être envoyés via ce compte de messagerie par jour.`,
    check_health_score: 'Vérifier le score de santé',
    delete_email: 'Voulez-vous supprimer ce compte de messagerie ?',
    mark_as_replied: 'marquer comme répondu',
    remove_from_sequence: 'supprimer de la séquence',
    move_to_step: "passer à l'étape",
    mark_as_finished: 'marquer comme terminé',
    no_results_found: 'No Results Found!',
    empty_prospects: 'Les perspectives sont vides',
    ...planSummary,
    click_here: 'Cliquez ici',
    ...prospectFilterLabels,
    upgrade_to_saleshandy_pro_email_sub: 'Passer à Pro',
    upgrade_required: 'Mise à niveau requise',
    ...planSummary,
    ...emailAccountTableHeader,
    turn_off_email_tracking_model_title:
      "Désactiver le suivi de l'ouverture des e-mails?",
    turn_off_mark_prospect_as_replied_model_title:
      'Désactiver la marque Prospect comme répondu?',
    ...prospectFilterLabels,
    your_subscription_end: 'Votre abonnement est terminé',
    your_trail_expired: 'Votre essai est expiré',
    use_unsubscribe_link_in_email:
      'Utiliser le lien de désabonnement dans les e-mails',
    canceled: 'Annulé',
    invoice: 'Factures',
    open_billing_portal: 'Ouvrir le portail de facturation',
    opt_out_option: 'Option de désinscription',
    update_system_field: 'Mettre à jour le champ système',
    update_custom_field: 'Mettre à jour le champ personnalisé',
    get_started: 'Commencer',
    edit_email_settings: 'Modifier les paramètres de messagerie',
    error_occured: 'Erreur est survenue!',
    connect: 'Relier',
    chrome_extension: 'Extension chromée',
    ...saleshandyUsageLabels,
    ...teamSizeLabels,
    ...industryLabels,
    view_sample_sequence: "Voir l'exemple de séquence",
    create_new_sequence: 'Créer une nouvelle séquence',
    take_action_now_cta: 'Agir dès maintenant',
    refresh: 'Rafraîchir',
    enable: 'Activer',
    see_all_features: 'Voir notre comparaison complète des fonctionnalités',
    most_value: 'LE PLUS VALEUR',
    most_popular: 'LE PLUS POPULAIRE',
    unused_balance: 'Solde inutilisé',
    credits: 'Crédits',
    manage_billing: 'Gérer la facturation',
    add_remove_slots: 'Ajouter ou supprimer des emplacements',
    update_card_details: 'Mettre à jour les détails de la carte',
    next: 'Prochain',
    thanks: 'Merci',
    regards: 'Cordialement',
    upgrade_to_saleshandy_email_sub:
      'Demande de mise à niveau de notre compte Saleshandy.',
    got_it: "J'ai compris",
  },
  messages: {
    NOTE: 'REMARQUE',
    and: `et`,
    min_max_interval_limit:
      "Limite d'intervalle minimum et maximum pour l'envoi automatisé e-mails",
    warning_cannot_add_to_sequence:
      'Les contacts déjà existants ou désabonnés/rejetés ne peuvent pas être ajoutés à la séquence',
    update_custom_field_warning:
      "Si vous modifiez le champ personnalisé, cela peut entraver la personnalisation de l'e-mail",
    warning_cannot_add_to_sequence_search_and_add:
      'Veuillez noter que les contacts non inscrits/rebondis ne seront pas ajoutés à la séquence',
    email_sent_on: 'Courriel envoyé le',
    email_opened_on: 'E-mail ouvert le',
    link_clicked_on: 'Lien cliqué sur',
    email_replied_on: 'E-mail répondu le',
    connect_email_account_description: `L'ajout d'un compte de messagerie est nécessaire pour envoyer n'importe quelle séquence à votre
    perspectives. Nous vous recommandons de le faire maintenant pour une intégration précieuse.
    Vous pouvez toujours modifier, supprimer ou ajouter un nouveau compte plus tard.`,
    thats_all_for_now: "C'est tout pour le moment!!",
    no_activity_data: 'Aucune activité trouvée',
    assign_prospect_to_sequence: 'Attribuer un prospect à des séquences',
    prospect_sequences_are_empty: 'Les séquences du prospect sont vides',
    cannot_change_setting_once_sequence_has_started:
      'Vous ne pouvez pas modifier les paramètres une fois que la séquence a commencé',
    help_remove_invalid_prospect:
      'La vérification des e-mails vous aidera à supprimer les prospects non valides de votre séquence.',
    two_types_of_prospect:
      "Vous trouverez ci-dessous les deux types de prospects auxquels nous vous recommandons d'envoyer des e-mails.",
    valid_prospect: 'Prospects valides',
    high_delivery_chances:
      'Les chances de livraison par e-mail sont très élevées.',
    risky_prospect: 'Perspective risquée',
    low_delivery_chances:
      'Les chances de livraison par e-mail sont très faibles.',
    email_verification: "vérification de l'E-mail",
    email_account_select_message:
      'Connectez votre compte de messagerie à partir duquel vous souhaitez envoyer des e-mails depuis Saleshandy.',
    email_account_connect_note:
      'Remarque : Nous ne stockons pas les données de votre boîte aux lettres et vous pouvez supprimer votre compte de messagerie à tout moment.',
    email_account_unknown_provider:
      'Vous ne connaissez pas votre fournisseur de services de messagerie?',
    verification_started: 'Vérification commencée',
    please_connect_email:
      'Ajoutez votre compte de messagerie pour démarrer une séquence',
    adding_email_account: `L'ajout d'un compte de messagerie est nécessaire pour envoyer n'importe quelle séquence à vos prospects. Nous vous recommandons de le faire maintenant pour une intégration précieuse. Vous pouvez toujours modifier, supprimer ou ajouter un nouveau compte ultérieurement.`,
    we_do_not_store:
      'Nous ne stockons aucune donnée de votre fournisseur de service de messagerie à des fins autres que de vous fournir des analyses.',
    create_and_add_prospect_successfully:
      'Créer et ajouter un prospect à séquencer avec succès',
    provide_field_name: 'Veuillez fournir un nom de champ',
    provide_valid_fallback_text:
      'Veuillez fournir un texte de remplacement valide',
    no_data_available: `Les données ne sont pas mises à jour dans cette section, elles apparaîtront ici une fois
    le prospect agit`,
    data_not_updated_for_selection: `Les données de cette sélection ne sont pas encore mises à jour !`,
    your_plan_expired_text: "L'essai expire dans",
    your_plan_expired_text_0_day: 'Votre essai gratuit a expiré',
    your_plan_free_text: 'Vous êtes en forfait Gratuit',
    subscription_warning:
      'Votre essai gratuit a expiré. Pour continuer, mettez à niveau votre forfait.',
    plan_expired_message: 'Euh-Oh ! Votre essai a expiré',
    plan_expired_message_content:
      'Pour créer des séquences, vous devez mettre à jour votre compte.',
    plan_expired_active_sequence_message:
      "Vous n'êtes pas autorisé à activer la séquence",
    plan_expired_active_sequence_content_1:
      "Vous n'êtes pas autorisé à activer la séquence car vous êtes en plan gratuit.",
    plan_expired_active_sequence_content_2:
      'Pour activer la séquence, vous devez mettre à jour votre compte.',
    plan_expired_add_email_message:
      "Vous n'êtes pas autorisé à ajouter les comptes de messagerie",
    plan_expired_add_email_content_1:
      "Vous n'êtes pas autorisé à ajouter les comptes de messagerie car vous êtes dans le plan gratuit.",
    plan_expired_add_email_content_2:
      'Pour ajouter plus de comptes de messagerie, vous devez mettre à niveau votre compte.',
    plan_expired_connect_email_message:
      "Vous n'êtes pas autorisé à connecter les comptes de messagerie",
    plan_expired_connect_email_content_1:
      "Vous n'êtes pas autorisé à connecter les comptes de messagerie car vous êtes dans le plan gratuit.",
    plan_expired_connect_email_content_2:
      'Pour connecter plus de comptes de messagerie, vous devez mettre à niveau votre compte.',
    plan_expired_invite_member_message:
      "Vous n'êtes pas autorisé à inviter les membres de l'équipe",
    plan_expired_invite_member_content_1:
      "Vous n'êtes pas autorisé à inviter les membres de l'équipe car vous êtes dans le plan gratuit.",
    plan_expired_invite_member_content_2:
      "Pour inviter des membres de l'équipe, vous devez mettre à jour votre compte.",
    plan_expired_clone_sequence_message:
      'Mettre à jour le plan pour cloner une séquence',
    plan_expired_create_api_key:
      'Mettre à niveau le plan pour créer une clé API',
    email_verification_title:
      "Après l'importation, nous effectuerons une vérification des e-mails qui vous aidera à supprimer les prospects non valides de votre séquence. Vous trouverez ci-dessous les deux types de prospects auxquels nous vous recommandons d'envoyer des e-mails.",
    number_input_alert_message:
      "Remarque : - Vous ne pouvez pas rétrograder le numéro. de comptes dans le cas où vous avez plus de numéros connectés. Si vous souhaitez en ajouter d'autres, supprimez ceux qui sont connectés et ajoutez-les à la place ou mettez-les à niveau.",
    upgrade_plan_header_text:
      'Le plan Pro comprend toutes les fonctionnalités ainsi que des séquences, des prospects et des modèles illimités.',
    trial_plan_message:
      "Vous êtes dans le plan d'essai gratuit et votre compte sera restreint après la période d'essai. Pour continuer, veuillez mettre à niveau votre forfait.",
    free_plan_message:
      'Votre essai gratuit a expiré. Pour continuer, mettez à niveau votre forfait',
    pro_to_free_plan_message:
      "Votre abonnement est terminé et vous bénéficiez d'un forfait gratuit. Pour continuer, veuillez mettre à niveau votre plan.",
    payment_failed_message:
      "Votre paiement n'a pas été traité avec succès. S'il te plaît",
    upgrade_plan_contact_admin:
      'Votre essai gratuit a expiré. Pour continuer, contactez votre administrateur.',
    make_changes_to_daily_sending_limit:
      "Voulez-vous vraiment modifier la limite d'envoi quotidienne ?",
    you_have_already_sent: 'Vous avez déjà envoyé',
    emails_today: "courriels aujourd'hui. Vous pouvez envoyer",
    more_emails_today: "plus d'e-mails aujourd'hui.",
    limit_restoration_tomorrow:
      "e-mails qui dépassent votre limite d'envoi d'e-mails pour aujourd'hui. Votre limite d'envoi quotidienne sera rétablie demain.",
    include_risky_prospects: 'Inclure les prospects à risque dans la séquence',
    smtp_message_one:
      'Nous prenons en charge tous les ESP et vous pouvez le connecter avec la configuration SMTP/IMAP.',
    smtp_message_two:
      'Vous pouvez connecter un compte SMTP/IMAP avec un guidage vidéo afin que le compte de messagerie puisse être lié sans difficulté.',
    smtp_message_three:
      'Ajoutez un compte de messagerie SMTP/IMAP à partir des paramètres et exécutez vos séquences de manière transparente.',
    delete_email_account_message:
      "Une fois que vous avez supprimé un compte de messagerie, toutes les séquences utilisant le compte de messagerie seront suspendues. Si une séquence n'est pas lancée, vous pouvez modifier le compte de messagerie et le reprendre plus tard. Les autres séquences ne peuvent pas être activées ultérieurement.",
    delete_default_email_account_message:
      'Il semble que vous essayez de supprimer un compte de messagerie par défaut. Veuillez marquer un autre compte de messagerie par défaut pour supprimer ce compte.',
    disconnect_email_account_message:
      'Une fois que vous avez déconnecté le compte de messagerie, le préchauffage des e-mails sera arrêté pour ce compte de messagerie.',
    create_api_token_label:
      'Choisissez une étiquette courte, mémorable et facile à retenir.',
    show_api_token:
      'Assurez-vous de copier votre nouvelle clé API. Vous ne pourrez plus voir cette clé.',
    delete_api_token_part_one:
      'Êtes-vous sûr de vouloir supprimer définitivement votre clé API ',
    delete_api_token_part_two:
      " ? La suppression d'une clé API n'est pas réversible.",
    api_key_copied_to_clipboard: 'Clé API copiée dans le presse-papiers',
    your_new_api_key: 'Votre nouvelle clé API',
    your_api_key_deleted: 'Votre clé API a été supprimée',
    delete_api_key: 'Supprimer la clé API',
    reached_email_sending_limit_message_one:
      "Vous avez atteint la limite d'envoi d'e-mails de 200 e-mails pendant la période d'essai gratuite.",
    reached_email_sending_limit_message_two:
      'Améliorez votre plan pour continuer à créer de nouvelles séquences.',
    reached_email_sending_limit_message_for_member:
      'Contactez votre administrateur pour mettre à niveau votre plan.',
    user_subscription_text:
      "Veuillez entrer le nombre d'emplacements que vous souhaitez ajouter.",
    email_account_subscription_text:
      'Saisissez le nombre de comptes de messagerie supplémentaires que vous souhaitez connecter.',
    user_plan_description:
      "Ajoutez des emplacements pour pouvoir connecter des comptes de messagerie supplémentaires et ajouter des membres d'équipe.",
    email_account_plan_description:
      'Cela vous permet de connecter des comptes de messagerie supplémentaires dans le même compte. Chaque utilisateur obtient un compte de messagerie par défaut.',
    your_plan_details: 'Détails de votre forfait Saleshandy',
    slots_info:
      "*Les créneaux horaires achetés correspondent au quota partagé de comptes de messagerie entre vous et les membres de votre équipe. La taille de votre équipe (y compris vous) peut être étendue jusqu'aux créneaux achetés.",
    unsubscribe_prospect_message_one:
      'Êtes-vous sûr de vouloir désinscrire les prospects sélectionnés.',
    unsubscribe_prospect_message_two:
      "Si vous désabonnez les prospects, vous ne pourrez pas envoyer d'e-mails aux prospects.",
    delete_prospect_message_one:
      'Êtes-vous sûr de vouloir supprimer les prospects sélectionnés.',
    delete_prospect_message_two:
      'Si vous supprimez les prospects, vous perdrez définitivement vos prospects.',
    active: 'Active',
    paused: 'En pause',
    draft: 'Brouillon',
    email_verification_error: "Erreur de vérification de l'e-mail",
    low_ev_credits:
      'Comme les crédits EV requis pour vérifier les e-mails sont faibles. Vous devez donc acheter plus de crédits pour vérifier les e-mails.',
    verification_credits: 'Crédits de vérification',
    buy_credits: 'Acheter des crédits',
    skip_verification: 'Ignorer la vérification',
    purchase_ev_credits_agreement:
      "En achetant des crédits, j'accepte que les crédits de vérification des e-mails ne soient pas remboursables.",
    email_verification_action_required_message:
      'Comme les crédits EV requis pour vérifier les e-mails sont faibles.' +
      'Vous devez donc acheter plus de crédits pour vérifier les e-mails.' +
      'Les crédits EV disponibles sont indiqués ci-dessous:-',
    fallback_text_info:
      'Un texte de secours est un texte de substitution lorsque le champ personnalisé est vide.',
    custom_fields_empty_list_description:
      'Vous pouvez ajouter des champs personnalisés numériques et textuels.',
    custom_fields_empty_list_description2:
      'Les champs personnalisés peuvent être utilisés pour stocker les données des prospects et envoyer des e-mails hautement personnalisés.',
    custom_fields_empty_list_title: 'Créez votre premier champ personnalisé',
    custom_field_update_successfully:
      'Champ personnalisé mis à jour avec succès',
    system_field_update_successfully: 'Champ système mis à jour avec succès',
    prospect_not_selected_warning:
      'Please select at least 1 prospect to complete the action',
    report_graph_tooltip:
      'Comparez toutes les mesures des séquences sélectionnées au cours de la période donnée.',
    verify_status_setting_banner_message:
      "L'envoi d'e-mails à des prospects non vérifiés peut entraîner un nombre" +
      "élevé d'e-mails rejetés, ce qui affecte considérablement votre réputation d'e-mail. Cliquez ici pour en savoir plus.",
    verify_status_pending_banner_message:
      'La vérification des prospects a été lancée. Vous recevrez un e-mail une fois la vérification terminée',
    modify_subscription_note:
      "La dernière suppression de siège de compte de messagerie s'appliquera à votre facturation après le cycle de facturation en cours.",
    turn_off_mark_prospect_as_replied_message:
      "La désactivation de cette option permettra au système d'envoyer des suivis aux prospects même après qu'ils aient répondu à vos e-mails.",
    are_you_sure_want_to_turn_it_off: 'Voulez-vous vraiment le désactiver ?',
    track_email_opens_model_message:
      "La désactivation de cette option arrêtera le suivi de l'ouverture des e-mails pour tous les e-mails de cette séquence.",
    upgrade_to_pro_email_body:
      'Il semble que notre compte soit rétrogradé au forfait gratuit. Je vous demanderais de prendre des mesures rapides pour mettre à niveau notre compte.',
    subscription_ended_message: 'Votre abonnement est terminé. Continuer,',
    trail_expired: 'Votre essai a expiré. Continuer,',
    trail_plan_expired_email_account_page_message:
      "Votre essai a expiré et vos comptes de messagerie sont déconnectés. Pour reconnecter l'e-mail,",
    plan_expired_team_page_message:
      "Votre abonnement est terminé. Pour inviter les membres de l'équipe,",
    trial_plan_expired_team_page_message:
      "Votre essai a expiré. Pour inviter des membres de l'équipe,",
    unverified_prospect_banner_message:
      "L'envoi d'e-mails à des prospects non vérifiés peut entraîner un nombre" +
      "élevé d'e-mails rejetés, ce qui affecte considérablement votre réputation d'e-mail. Cliquez ici pour en savoir plus.",
    ev_credits_payment_failed_message:
      'Paiement échoué! Veuillez réessayer après un certain temps à partir de la section de facturation.',
    verification_started_content:
      'Vous recevrez un e-mail une fois la vérification terminée',
    buy_email_credits_sequence_settings_message:
      "Pour vérifier l'adresse e-mail, vous devez acheter des crédits de vérification d'e-mail.",
    delete_email_message_one:
      'Une fois que vous avez supprimé un compte de messagerie, toutes les séquences utilisant le compte de messagerie seront suspendues.',
    delete_email_message_two:
      "Si une séquence n'est pas démarrée, vous pouvez changer de compte de messagerie et la reprendre plus tard. ",
    delete_email_message_three:
      'Les autres séquences ne peuvent pas être activées ultérieurement.',
    delete_email_message_four:
      "Si l'échauffement est activé pour ce compte, il sera alors désactivé et supprimé de la liste des comptes de messagerie d'échauffement.",
    step_1:
      'Ajoutez une étape de suivi pour augmenter le taux de réponse de votre séquence de 40 %.',
    step_2:
      "C'est souvent le suivi qui fait réagir les prospects. Ajoutons une étape de plus.",
    step_3:
      'Ajoutez une étape de suivi supplémentaire pour augmenter encore les taux de réponse.',
    upgrade_plan_for_add_email_account:
      'Votre compte est maintenant sur le plan gratuit et votre administrateur doit mettre à niveau votre compte pour reconnecter ce compte de messagerie.',
    no_prospect_found_in_sequence:
      "Aucun prospect n'a été trouvé dans la séquence",
    general_email_sub_for_purchase_ev:
      "Demande d'achat de crédit de vérification par e-mail",
    email_sub_for_purchase_ev_from_sequence_settings:
      'Acheter des crédits pour vérifier le prospect',
    upgrade_plan_for_add_email_account_admin:
      'Votre compte est maintenant sur le plan gratuit et vous devez mettre à niveau votre compte pour reconnecter ce compte de messagerie.',
    admin_block_page_message:
      "Vos séquences sont en pause. Veuillez mettre à jour votre compte pour continuer à utiliser ou accéder aux données. Besoin d'aide? Cliquez sur l'icône de chat pour nous contacter.",
    member_block_page_message:
      "Vos séquences sont en pause. Pour continuer à utiliser votre compte, veuillez demander à l'administrateur de vous réinscrire. Besoin d'aide? Cliquez sur l'icône de chat pour nous contacter.",
    paid_plan_expired_message:
      "Vos séquences sont en pause. Veuillez vous réabonner pour continuer à utiliser votre compte et accéder aux données. Besoin d'aide? Cliquez sur l'icône de chat pour nous contacter.",
    create_new_prospect_description:
      'Vous pouvez créer un nouveau prospect en cliquant sur le bouton ci-dessous.',
    import_prospect_and_start_engaging:
      'Importez vos prospects ou ajoutez-les manuellement et commencez à vous engager avec eux.',
    select_email_service:
      'Sélectionnez le service de messagerie que vous utilisez',
    team_size: 'Quelle est la taille de votre entreprise?*',
    user_industry: 'Dans quelle industrie etes-vous?*',
    user_job_role: 'Quelle est votre fonction ?*',
    use_case: "Qu'est-ce qui décrit le mieux votre cas d'utilisation?*",
    saleshandy_usage: 'Qui utilisera Saleshandy ?',
    subscription_canceled: 'Vous avez annulé cet abonnement.',
    subscription_paused: 'Votre abonnement est suspendu',
    reactivate_subscription_message_one:
      "Vous avez annulé votre abonnement existant et il est prévu qu'il se termine après",
    reactivate_subscription_message_two:
      "Veuillez le réactiver pour modifier l'abonnement en cours.",
    reactivate_subscription: 'veuillez réactiver notre abonnement saleshandy',
    reactivate_subscription_email_body:
      'il semble que notre abonnement Saleshandy soit annulé pour le moment. Je vous demanderais de le réactiver afin que je puisse accéder à mon compte.',
    resume_subscription_admin_message_one:
      'Votre abonnement est suspendu et doit reprendre le',
    resume_subscription_admin_message_two:
      "Afin d'effectuer cette activité, vous devez reprendre votre abonnement.",
    resume_subscription_member_message_one:
      "Votre abonnement a été mis en pause par l'administrateur et il est prévu qu'il reprenne le",
    resume_subscription_member_message_two:
      "Afin d'effectuer cette activité, veuillez demander à votre administrateur de reprendre votre abonnement.",
    resume_subscription: 'veuillez reprendre notre abonnement saleshandy',
    resume_subscription_success_message:
      "L'abonnement a été réactivé avec succès",
    resume_subscription_email_body:
      'il semble que notre abonnement Saleshandy soit suspendu pour le moment. Je vous demanderais de le reprendre afin que je puisse accéder à mon compte.',
    no_seat_available_email_modal_title: 'Ajouter des comptes de messagerie',
    no_seat_available_email_modal__content_1:
      "Vous avez atteint la limite d'ajout de comptes de messagerie).",
    no_seat_available_email_modal__content_2:
      'Pour ajouter plus de comptes de messagerie, vous devez acheter des emplacements supplémentaires',
    no_seat_available_team_modal_title: "Inviter les membres de l'équipe)",
    no_seat_available_team_modal__content_1:
      "Vous avez atteint une limite d'invitation de membres d'équipe).",
    no_seat_available_team_modal__content_2:
      "Pour inviter plus de membres d'équipe, vous devez acheter des emplacements supplémentaires.",
    plan_description:
      "Vous pouvez consulter vos factures et l'historique de facturation sur votre portail de facturation.",
    manually_cancel_subscription:
      "Votre abonnement a été créé manuellement. Veuillez contacter l'équipe d'assistance pour annuler votre abonnement.",
    select_unsubscribe_message_text:
      'Sélectionnez le type de message de désabonnement ci-dessous.',
    update_smtp_imap_connect_failed:
      'Toutes les séquences associées à ce compte de messagerie ont été mises en pause.',
    not_allowed_to_add_email_accounts_message_1:
      "Vous n'êtes pas autorisé à ajouter les comptes de messagerie car vous avez annulé votre abonnement existant et il est prévu qu'il se termine après",
    not_allowed_to_add_email_accounts_message_2:
      "Veuillez le réactiver pour ajouter d'autres comptes de messagerie.",
    please_enter_valid_email:
      "S'il vous plaît, mettez une adresse email valide",
    sender_name_limit_error: '50 caractères maximum autorisés',
    email_account_not_found:
      'Le compte que vous essayez de reconnecter est manquant',
    no_result_found_title:
      "Désolé, nous n'avons pas trouvé ce que vous cherchez",
    no_result_found_desc: 'Veuillez essayer de rechercher avec un autre terme',
    now_allowed_to_add_unsubscribe_contact_to_sequence:
      'It is not possible to add unsubscribed contacts to the sequence',
    view_only_access_to_user: "L'utilisateur aura un accès en lecture seule.",
    payment_pending: 'Le paiement de votre abonnement est en attente.',
    initial_payment_pending_text_one:
      'Le paiement de votre abonnement est en attente.',
    initial_payment_pending_text_two:
      "votre abonnement ne sera activé qu'après un paiement réussi dans les prochaines 23 heures.",
    recurring_payment_pending_text_two:
      'pour éviter que votre plan ne soit annulé et ne passe au plan gratuit dans le prochain',
    download_chrome_plugin: 'Télécharger le plug-in Chrome',
    email_account_sending_limit_tooltip: 'Cliquez ici pour modifier la limite',
    email_account_health_score_tooltip:
      'Cliquez ici pour voir votre score par e-mail',
    dont_know_your_email_provider:
      'Vous ne connaissez pas votre fournisseur de service de messagerie?',
    monthly_highlight_message:
      'Changez chaque année pour obtenir 3 mois gratuits',
    yearly_highlight_message: 'Vous économisez 3 mois gratuitement',
    monthly_outreach_highlight_message:
      "Passez à l'année pour obtenir jusqu'à 20 % de réduction",
    yearly_outreach_highlight_message: "Vous économisez 20 % sur l'année",
    what_is_tentative_date_1:
      "Il s'agit d'une date provisoire pour l'envoi d'e-mails à cette étape, en supposant que vous ajoutez des prospects aujourd'hui.",
    what_is_tentative_date_2:
      "La date réelle peut changer selon le calendrier ci-joint, le quota d'envoi et d'autres séquences en cours.",
    upgrade_plan_error_email_subject:
      'Demande de mise à niveau du plan Saleshandy',
    upgrade_plan_error_email_body1:
      "Je vous écris pour demander une mise à niveau appropriée dans le compte Saleshandy actuel afin qu'il me permette d'utiliser sa meilleure fonctionnalité.",
    upgrade_plan_error_email_body2:
      "S'il vous plaît, faites-moi savoir s'il y a autre chose que je peux faire pour que cette demande se concrétise.",
    drag_and_drop_csv:
      'Faites glisser et déposez votre CSV avec les détails du prospect',
    drop_your_csv_here: 'Déposez pour télécharger votre fichier CSV ici.',
    ...onboarding.step3,
    ...onboarding.step4,
    ...onboarding.step5,
    ...onboarding.step6,
    ...onboarding.step7,
    ...onboarding.step8,
    ...emptyList.emailsTab,
    ...emptyList.templates,
    ...templates,
    ...emails,
    ...tags,
    ...blacklist,
    ...profileProgress,
    ...apiKey,
    ...sequencePauseMessages,
    ...emailEditorModals,
    ...coldEmailTips,
    ...emailSuggestions,
    ...needHelpCta,
    ...customTrackingDomainMessages,
    ...rampUpSettings,
    ...emailHealthScore,
    ...noSeatAvailableEmail,
    ...unsubscribeLinkModal,
    ...SMTPIMAPConnection,
    ...tipsToConnectSMTPIMAPEmail,
    ...watchVideoModal,
    ...mailboxEmails,
    ...chromeExtensionSettings,
    ...emailWarmup,
    ...errorBoundary,
    ...emailAccountType,
    ...nativeAttachment,
    ...timeIntervalSettings,
    ...adminSettings,
    ...outreachBasicRestrictionMsg,
  },
};
